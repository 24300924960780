<template>
  <card
    :href="`/releases${product.slug}`"
    :clickable="true"
    :badge="product.devicon ? `https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${product.devicon}/${product.devicon}-original.svg` : undefined"
    :wide="wide"
    style="background: white;"
  >
    <badge
      :title="product.name"
      :subtitle="badgeSubtitle"
      :subtitle_href="badgeSubtitleHref"
      :src="badgeSrc"
      :depth="3"
      :is_legacy="product.is_legacy || (product.product_group && product.product_group.is_legacy)"
    />
    <div v-if="product.is_open_source" class="pill">Open-Source</div>
    <p class="description" v-if="product.description">{{ product.description }}</p>
    <small class="supported" v-if="showSupported && supported && supported.length > 0">
      <span class="pairs">{{ supported.map(x => x.values ? `${x.key} ${x.values.join("/")}` : x.key).join( ", " ) }}</span>
    </small>
  </card>
</template>

<script>
import Badge from "../UI/Badge.vue";
import Tag from "../icons/Tag.vue";
import Card from "../UI/Card.vue";
import * as Releases from "../../utils/js/releases.js";

export default {
  name: "ProductCard",
  props: ["product", "showSupported", "wide"],
  components: {
    Badge,
    Tag,
    Card,
  },
  computed: {
    supported() {
      return this.product.latest_release ? Releases.reduceConfiguration(this.product.latest_release) : undefined
    },
    badgeSubtitle() {
      if (this.product.external_url) {
        return 'external'
      } else if (this.product.releases.length > 0) {
        return `${this.product.releases.length} ${this.product.releases.length == 1 ? 'release' : 'releases'}`
      } else {
        return undefined
      }
    },
    badgeSubtitleHref () {
      return undefined
    },
    badgeSrc() {
      const product = this.product
      if ( product.icon ) {
        return product.icon.url
      } else if ( product.devicon ) {
        return `https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${product.devicon}/${product.devicon}-original.svg`
      } else {
        return undefined
      }
    },
  },
};
</script>

<style lang="stylus">
.description {
  margin-bottom unset
}

.supported {
  font-weight 200
  transform translateY(2px)
}
</style>