import Vue from 'vue'
import Vuex from 'vuex'
import * as API from "../utils/js/api";
import * as Entries from "../utils/js/entries"

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    collapsedSideBar: true,
    articleSelectedPart: "",
    currVersion: "0",
    docsTree: [],
    documents: [],
    products: [],
    productGroups: [],
    productSubgroups: [],
    releases: [],
    productPaths: {},
    articlePaths: {},
  },
  mutations: {
    onFetchDocuments(state, data) {
      state.documents = data
    },
    onFetchProducts(state, data) {
      state.products = data
    },
    onFetchProductGroups(state, data) {
      state.productGroups = data
    },
    onFetchProductSubgroups(state, data) {
      state.productSubgroups = data
    },
    onFetchReleases(state, data) {
      state.releases = data
    },
    onResolveProductPaths(state, data) {
      state.articlePaths = data
    },
    onResolveDocumentPaths(state, data) {
      state.productPaths = data
    }
  },
  actions: {
    async fetchAll(context) {
      const productsResponse = await API.get(`/products`, {
        'populate[product_group]': 1,
        'populate[product_subgroup]': 1,
        'populate[releases][populate][downloads][populate]': '*',
        'populate[icon]': 1,
      })
      const productGroupsResponse = await API.get(`/product-groups`)
      const productSubgroupsResponse = await API.get(`/product-subgroups`)
      const releasesResponse = await API.get(`/releases`, {
        'populate[downloads][populate][configuration]': 1,
        'populate[downloads][populate][artifacts]': 1,
        'populate[product][populate][product_group]': 1,
        'populate[product][populate][product_subgroup]': 1,
        'populate[product][populate][icon]': 1,
      })
      
      // const documents = restructureListResponse(documentsResponse)
      // const documents = restructureListResponse(documentsResponse)
      const products = Entries.restructureListResponse(productsResponse)
      const productGroups = Entries.restructureListResponse(productGroupsResponse)
      const productSubgroups = Entries.restructureListResponse(productSubgroupsResponse)
      const releases = Entries.restructureListResponse(releasesResponse)

      const productPaths = Entries.resolveProductPaths(products)
      // const documentPaths = resolveDocumentPaths(documents)

      // context.commit("onfetchAllTree", treeBuilder(documents))
      // // context.commit("onFetchDocuments", documents)
      // context.commit("onFetchDocuments", documents)
      context.commit("onFetchProducts", products)
      context.commit("onFetchProductGroups", productGroups)
      context.commit("onFetchProductSubgroups", productSubgroups)
      context.commit("onFetchReleases", releases)
      context.commit("onResolveProductPaths", productPaths)
      // context.commit("onResolveDocumentPaths", documentPaths)
    }
  },
  modules: {
  }
})
