<template>
  <div class="docs">
    <webgl-background />
    <div class="background-fade"></div>
    <main-header class="main-header-light" :breadcrumbs="['Releases']"/>
    <div class="docs_success">
      <div class="page">
        <h1 class="page-title">📦 Releases</h1>
        <div class="page-content" v-if="getReleases">
          <h1>All Releases</h1>
          <p>Software and APIs for Haply devices. For a feed view, visit the <router-link to="/releases/latest" tag="a">latest releases</router-link> page.</p>
          <div v-if="getReleases.products && getReleases.products.length > 0">
            <h2 class="heading">✨ Featured</h2>
            <card-layout>
              <card-row>
                <product-card v-for="product in getReleases.products"
                  :key="product.id"
                  :product="product"
                  :showSupported="true"
                  :wide="product.emphasize"
                />
              </card-row>
            </card-layout>
          </div>
          <div v-for="group in getReleases.groups" :key="group.id">
            <div v-for="subgroup in group.subgroups" :key="subgroup.id">
              <h2 class="heading">
                <span class="group-name">{{ group.name }}</span> <span class="separator">|</span> <span class="subgroup-name">{{ subgroup.plural_name_in_group }}</span>
              </h2>
              <p v-if="subgroup.description">{{ subgroup.description }}</p>
              <card-layout>
                <card-row>
                  <product-card v-for="product in subgroup.products"
                    :key="product.id"
                    :product="product"
                    :showSupported="true"
                    :wide="product.emphasize"
                  />
                </card-row>
              </card-layout>
            </div>
            <div v-if="group.products && group.products.length > 0">
              <h2 class="heading">
                <small v-if="group.subgroups && group.subgroups.length > 0">More in <span class="group-name">{{ group.name }}</span></small>
                <span v-else class="group-name">{{ group.name }}</span>
              </h2>
              <card-layout>
                <card-row>
                  <product-card v-for="product in group.products"
                    :key="product.id"
                    :product="product"
                    :showSupported="true"
                    :wide="product.emphasize"
                  />
                </card-row>
              </card-layout>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebglBackground from "../components/home/WebglBackground"
import ReleaseTag from "../components/releases/ReleaseTag"
import Timestamp from "../components/UI/Timestamp"
import MainHeader from "../components/UI/MainHeader"
import Card from "../components/UI/Card.vue"
import CardRow from "../components/UI/CardRow.vue"
import CardLayout from "../components/UI/CardLayout.vue"
import Outline from "../components/docs/Outline"
import Tree from "../components/docs/Tree.vue"
import ProductCard from '../components/releases/ProductCard.vue'
import * as API from "../utils/js/api"
import * as Entries from "../utils/js/entries"
import * as Releases from "../utils/js/releases"

export default {
  components: {
    WebglBackground,
    MainHeader,
    ReleaseTag,
    Timestamp,
    Card,
    CardRow,
    CardLayout,
    Outline,
    Tree,
    ProductCard,
  },
  data() {
    return {
      docsLoaded: false
    };
  },
  computed: {
    getReleases() {
      if (this.$store.state.products[0]) {
        this.docsLoaded = true
        const products = this.$store.state.products
        const productGroups = this.$store.state.productGroups
        const productSubgroups = this.$store.state.productSubgroups
        const productsWithReleaseOrExternalUrl = []
        for (const product of products) {
          if (product.releases.length > 0) {
            for (const release of product.releases) {
              release.product = product
            }
            const releases_by_date_created = [...product.releases].sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
            const latest_release = this.$store.state.releases.find(r => r.id == releases_by_date_created[0].id)
            product['latest_release'] = latest_release
            productsWithReleaseOrExternalUrl.push(product)
          } else if (product.external_url) {
            productsWithReleaseOrExternalUrl.push(product)
          }
        }
        const productsByGroup = Releases.byProductGroup(productsWithReleaseOrExternalUrl, productGroups, productSubgroups)
        return productsByGroup
      }
    }
  },
};
</script>

<style lang="stylus">
h2.heading {
  font-weight 200
  
  span.group-name {
    font-weight 400
  } 
}

.card {
  small {
    color $secondary
  }
}

.label {
  display inline-block
  text-transform uppercase
  font-weight 400
  background alpha($primary, 50%)
  color white
  padding 0 0.3em
  border-radius $borderRadiusSm
  font-size 0.75em !important
}

small div.label {
  font-size 0.8em !important
  font-weight 600
}
</style>