<template>
  <div>
    <div v-if="release.notes" class="release-subsection" :class="{collapse: collapse, expanded: notesExpanded}">
      <component :is="`h${depth}`" class="release-subsection-heading collapse-foldout" @click="toggleNotesExpanded" :id="anchors ? 'notes' : null">
        Notes
      </component>
      <div class="collapse-content">
        <div v-if="notesLoading">Loading...</div>
        <div v-else-if="notesError">Error</div>
        <div v-else v-html="notesHtml" class="release-notes"></div>
      </div>
    </div>
    
    <div v-if="release.changelog" class="release-subsection" :class="{collapse: collapse, expanded: changelogExpanded}">
      <component :is="`h${depth}`" class="release-subsection-heading collapse-foldout" @click="toggleChangelogExpanded" :id="anchors ? 'changelog' : null">
        Changelog
      </component>
      <div class="collapse-content">
        <div v-if="changelogLoading">Loading...</div>
        <div v-else-if="changelogError">Error</div>
        <div v-else v-html="changelogHtml" class="release-changelog"></div>
      </div>
    </div>
    
    <div v-if="release.downloads && release.downloads.length" class="release-subsection" :class="{collapse: collapse, expanded: bundlesExpanded}">
      <component :is="`h${depth}`" class="release-subsection-heading collapse-foldout" @click="toggleBundlesExpanded" :id="anchors ? 'downloads' : null">
        Downloads<span v-if="release.downloads.length > 2"> ({{ release.downloads.length }})</span>
      </component>
      <div class="collapse-content release-bundles">
        <div v-for="download in release.downloads" class="bundle">
          <div v-if="download.configuration && download.configuration.length" class="bundle-subsection">
            <component :is="`h${depth + 1}`" class="bundle-subsection-heading">Configuration</component>
            <span class="bundle-configuration pills">
              <span v-for="entry in download.configuration" class="pill">
                <span v-if="entry.key" class="pill-key">
                  {{entry.key}}
                </span>
                <span v-if="entry.key" class="pill-value">
                  {{entry.value}}
                </span>
                <span v-else class="pill-key">
                  {{entry.value}}
                </span>
              </span>
            </span>
          </div>
          <div v-if="download.artifacts && download.artifacts.length" class="bundle-subsection">
            <component :is="`h${depth + 1}`" class="bundle-subsection-heading">Artifacts</component>
            <div v-for="artifact in download.artifacts" class="bundle-artifacts">
              <a :href="`${artifact.url}`" class="bundle-artifact">
                <span>
                  <span class="bundle-artifact-icon">📦</span> <span class="bundle-artifact-name">{{artifact.name}}</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-else-if="!collapse">There are no downloads associated with this release.</p>
  </div>
</template>

<script>
import Tag from "../icons/Tag.vue";
import ReleaseTitle from "./ReleaseTitle.vue";
import * as API from "../../utils/js/api";

export default {
  name: "Release",
  components: {
    Tag,
    ReleaseTitle,
  },
  props: {
    release: Object,
    depth: {
      default: 1,
    },
    collapse: {
      default: false,
    },
    compact: {
      default: false,
    },
    anchors: {
      default: false,
    }
  },
  data () {
    return {
      notesLoading: true,
      notesError: false,
      notesHtml: null,
      notesExpanded: false,
      changelogLoading: true,
      changelogError: false,
      changelogHtml: null,
      changelogExpanded: false,
      bundlesExpanded: false,
    }
  },
  methods: {
    toggleNotesExpanded () {
      this.notesExpanded = !this.notesExpanded
    },
    toggleChangelogExpanded () {
      this.changelogExpanded = !this.changelogExpanded
    },
    toggleBundlesExpanded () {
      this.bundlesExpanded = !this.bundlesExpanded
    },
  },
  async mounted() {
    const classList = ['richtext']
    if (this.compact) {
      classList.push('compact')
    }
    const className = classList.join(' ')
    if (this.release) {
      if (this.release.changelog) {
        const params = {type: 'release', id: this.release.id, attribute: 'changelog'}
        API.get(`/markdown`, params).then(response => {
          this.changelogHtml = `<div class="${className}">` + response.data.html + '</div>';
        }).catch(error => {
          this.changelogError = true;
        }).finally(() => {
          this.changelogLoading = false;
        });
      }
      if (this.release.notes) {
        const params = {type: 'release', id: this.release.id, attribute: 'notes'}
        API.get(`/markdown`, params).then(response => {
          this.notesHtml = `<div class="${className}">` + response.data.html + '</div>';
        }).catch(error => {
          this.notesError = true;
        }).finally(() => {
          this.notesLoading = false;
        });
      }
    }
  }
};
</script>

<style lang="stylus">
.internal-row {
  display flex
  flex-direction row
  justify-content space-between
  margin-bottom 6px
}

.group {
  display inline-block
  font-size 0.8rem
  color $primary 
  font-weight 600
}

.subgroup {
  margin-left 0.3em
  display inline-block
  font-weight 200
}

.pills {
  display flex
  flex-direction row
  align-items flex-start
  gap 6px
}

.pill {
  position relative
  height 24px
  border-radius $borderRadiusMd
  background #b5c4cd
  color white
  padding-top 4px
  padding-left 10px
  padding-right 10px
  font-size 0.8rem
  cursor default
}

.pill-key {
  position relative
  height 100%
  display inline-block
  font-weight 600
}

.pill-value {
  position relative
  height 100%
  margin-left 0.3em
  display inline-block
  font-weight 200
}

.release {  
  background white

  &-version {
    color $secondary
  }
  
  &-version-tag {
    transform translate(0px, 0.09em)
    
    path:not([fill="none"]) {
      fill $secondary
    }
    circle:not([fill="none"]) {
      fill $secondary
    }
  }

  &:hover {
    .release-version {
      font-weight 400
      color $primary

      &:hover {
        color $active
      }
    }

    .release-version-tag {
      path:not([fill="none"]) {
        fill $primary
      }
      circle:not([fill="none"]) {
        fill $primary
      }
    }
    
    .pill {
      background alpha($primary, 50%)
    }

    .bundle-artifact-name {
      color $secondaryDarker
    }
  }

  &-subsection {
    &-heading {
      margin-bottom 8px
      font-weight 400
      color $greyMatter
    }

    &:not(:last-child) {
      margin-bottom 16px
    }

    &:not(.expanded) {
      margin-bottom 0
    }

    p, ul, ol {
      margin-top 8px
      font-weight 200
    }

    ul {
      font-weight 200
    }

    .richtext {
      :nth-child(2) {
        margin-top unset
      }
    }
  }

  &-bundles {
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content stretch
    gap 10px
    padding 1px

    & > .bundle {
      flex-grow 1
      min-width 33%
    }
  }

  .collapse:last-child {
    margin-bottom unset !important
  }
}

.bundle {
  position relative
  border-radius $borderRadiusMd
  border 0.5px solid $secondaryMedium
  padding 8px 10px 12px 10px

  &-subsection {
    &:not(:last-child) {
      margin-bottom 8px
    }

    &-heading {
      margin-top 0px
      margin-bottom 4px
      font-weight 200
      text-transform uppercase
      font-size 0.65rem
      color $secondary
      cursor default
    }
  }

  &-configuration {
    padding-top 2px
  }

  &-artifact {
    display block
    font-size 0.9rem
    text-decoration none !important
    
    &-icon {
      opacity 0.8
    }
    &-name {
      font-weight 400
      color $secondary
    }
  }

  &:hover {
    border-color blend(alpha($active, 50%), #fff)
    background alpha($active 5%)

    &::after {
      position absolute
      content " "
      top -1.5px
      left -1.5px
      bottom -1.5px
      right -1.5px
      border 2px solid blend(alpha($active, 50%), #fff)
      border-radius $borderRadiusMd + 3px
      pointer-events none
    }

    .bundle-subsection {
      &-heading {
        color $secondaryAccent
      }
    }

    .bundle-artifact {
      &-icon {
        opacity 1.0
      }
      &-name {
        color $secondaryAccent

        &:hover {
          text-decoration underline !important
          color $active
        }
      }
    }
    
    .pill {
      background alpha($primary, 70%)
    }
  }

  .pill {
    &[clickable]:hover {
      background blend(alpha($active, 90%), #fff)
      color white

      &::before {
        position absolute
        content " "
        top -1px
        left -1px
        bottom -1px
        right -1px
        border 2px solid blend(alpha($active, 90%), #fff)
        border-radius $borderRadiusMd + 2px
      }
    }
  }
}
</style>