import * as Entries from "./entries";

export const reduceConfiguration = (release) => {
  if (release.downloads) {
    const lookup = {}
    const supported = []
    for (const download of release.downloads) {
      if (download.configuration) {
        for (const pair of download.configuration) {
          const key = pair.key
          const value = pair.value
          if (key) {
            if (!(key in lookup)) {
              const values = [value]
              lookup[key] = values
              supported.push({
                'key': key,
                'values': values,
              })
            } else {
              const values = lookup[key]
              if (!(values.includes(value))) {
                values.push(value)
              }
            }
          } else if (!(value in lookup)) {
            if (value.toLowerCase() === 'release' || value.toLowerCase() === 'debug') {
              continue
            }
            lookup[value] = []
            supported.push({
              'key': value,
              'values': undefined,
            })
          }
        }
      }
    }
    return supported
  } else {
    return []
  }
}

export const byProductGroup = (products, product_groups, product_subgroups) => {
  return Entries.organizeBy(
    {
      entries: products,
      key: 'products',
    },
    {
      entries: product_groups,
      key: 'product_group',
    },
    {
      entries: product_subgroups,
      key: 'product_subgroup',
    }
  )
}

export const isPreRelease = (release) => {
  return release.version.includes('-') || release.version.includes('alpha') || release.version.includes('beta') || release.version.includes('rc') || release.version.includes('dev') || release.version.includes('pre')
}


export const semverCompare = (a, b) => {
  const semverfull = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
  const aMatch = semverfull.exec(a);
  const bMatch = semverfull.exec(b);
  if (aMatch && bMatch) {
    const a_parts = [aMatch[1], aMatch[2], aMatch[3]].map(Number);
    const b_parts = [bMatch[1], bMatch[2], bMatch[3]].map(Number);
    if (a_parts[0] == b_parts[0]) {
      if (a_parts[1] == b_parts[1]) {
        if (a_parts[2] == b_parts[2]) {
          // compare prerelease or build metadata
          if (aMatch[4] && bMatch[4]) {
            if (aMatch[4] == bMatch[4]) {
              if (aMatch[5] && bMatch[5]) {
                return aMatch[5] < bMatch[5] ? 1 : -1
              } else {
                return aMatch[5] ? 1 : -1
              }
            } else {
              // look for a number in the prerelease
              const aNum = aMatch[4].match(/\d+/)
              const bNum = bMatch[4].match(/\d+/)
              if (aNum && bNum) {
                return Number(aNum[0]) < Number(bNum[0]) ? 1 : -1
              }
              else
                return aNum ? 1 : 1
            }
          } // if one has prerelease and the other doesn't, the one without is greater
          else {
            return aMatch[4] ? 1 : -1
          }
        }
        else {
          return a_parts[2] < b_parts[2] ? 1 : -1
        }
      } else {
        return a_parts[1] < b_parts[1] ? 1 : -1
      }
    } else {
      return a_parts[0] < b_parts[0] ? 1 : -1
    }
  } else {
    return a < b ? 1 : -1
  }
}




export const getTimelineGroupLabel = (release) => {
  const localDate = new Date(release.createdAt)
  const now = new Date(Date.now())
  const dt = (now - localDate) / (1000 * 3600 * 24)

  let group = undefined

  if (dt < 1 && localDate.getDay() == now.getDay()) {
    group = "Today"
  } else if (dt < 2 && (localDate.getDay() + 1 % 7) == now.getDay()) {
    group = "Yesterday"
  } else if (dt < now.getDay()) {
    group = "This week"
  } else if (dt < 7 + now.getDay() + 1) {
    group = `Last week`
  } else if (now.getYear() == localDate.getYear()) {
    if (now.getMonth() == localDate.getMonth()) {
      group = "Earlier this month"
    } else if (now.getYear() == localDate.getYear()) {
      group = localDate.toLocaleString('en-CA', { month: 'long' })
    }
  } else {
    group = localDate.toLocaleString('en-CA', { year: 'numeric' })
  }

  return group
}

export const byTimelineGroup = (releases) => {
  let groups = []
  let currentGroupLabel = undefined
  let currentGroupReleases = undefined
  for (const release of releases) {
    console.log(release.version)
    const groupLabel = getTimelineGroupLabel(release)
    if (groupLabel !== currentGroupLabel) {
      if (currentGroupReleases) {
        groups.push({
          label: currentGroupLabel,
          releases: currentGroupReleases,
        })
      }
      currentGroupLabel = groupLabel
      currentGroupReleases = []
    }
    currentGroupReleases.push(release)
  }
  groups.push({
    label: currentGroupLabel,
    releases: currentGroupReleases,
  })
  return groups
}