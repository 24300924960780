<template>
  <card-layout v-if="releases && releases.length > 0" class="release-list">
    <card-row v-for="group in byTimelineGroup" :key="group.label">
      <div class="timeline-group-label">
        <div class="timeline-group-label-lhs"></div>
        <div class="timeline-group-label-text">{{ group.label }}</div>
        <div class="timeline-group-label-rhs"></div>
      </div>
      <card v-for="release in group.releases" :key="release.id" class="release" :wide="true"
        :href="`/releases${release.product.slug}/${release.version}`">
        <badge :release="release" :src="getBadgeSrc(release.product)" :depth="2" :is_legacy="isLegacy(release.product)"
          :small="true" />
        <div class="release-date">
          <timestamp :date="release.createdAtRelativeDate" :time="release.createdAtTime" />
        </div>
        <release :release="release" :depth="3" :collapse="true" :compact="true" />
      </card>
    </card-row>
  </card-layout>
  <div v-else>No releases found.</div>
</template>

<script>
import Card from "../UI/Card.vue";
import CardRow from "../UI/CardRow.vue";
import CardLayout from "../UI/CardLayout.vue";
import Release from "./Release.vue";
import Badge from "../UI/Badge.vue";
import Timestamp from "../UI/Timestamp.vue";
import * as Releases from "../../utils/js/releases"

export default {
  name: "ReleaseList",
  props: ["releases"],
  components: {
    Card,
    CardRow,
    CardLayout,
    Release,
    Badge,
    Timestamp,
  },
  computed: {
    byTimelineGroup() {
      const releases_with_products = this.releases.filter(x => x.product)
      releases_with_products.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      const products = []
      for (const release of releases_with_products) {
        if (!products.includes(release.product)) {
          products.push(release.product)
        }
      }
      for (const product of products) {
        product.releases.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        product.latest_release = product.releases[0]
      }
      return Releases.byTimelineGroup(releases_with_products)
    },
  },
  methods: {
    getBadgeSrc(product) {
      if (product.icon) {
        return product.icon.url
      } else if (product.devicon) {
        return `https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${product.devicon}/${product.devicon}-original.svg`
      }
    },
    isLegacy(product) {
      return product.is_legacy || (product.product_group && this.isLegacyGroup(product.product_group))
    },
    isLegacyGroup(productGroup) {
      return productGroup.is_legacy
    }
  },
  beforeDestroy() { }
};
</script>

<style lang="stylus">
.timeline-group-label {
  display flex
  width 100%

  .timeline-group-label-lhs, .timeline-group-label-rhs {
    flex-grow 1
    height 1px
    margin-top 0.7em
  }
  .timeline-group-label-rhs {
    background linear-gradient(90deg, alpha($secondary, 50%), alpha($secondary, 0%))
  }
  .timeline-group-label-lhs {
    background linear-gradient(270deg, alpha($secondary, 50%), alpha($secondary, 0%))
  }
  .timeline-group-label-text {
    font-weight 200
    margin 0px 10px
    color $secondary
    user-select none
  }
}
.release {
  &-date {
    margin-bottom 6px
  }
}

.releases-latest {
  &_release {
    &_link {

      & > span {
        display inline-block
      }
    }
  }
}

.fun-button--unused {
  &:hover {
    border-radius $borderRadiusSm
    background #99d
    color #fff
    box-shadow 0px 3px 0px #44a
  }

  &:active {
    border-radius $borderRadiusSm
    background #99d
    color #fff
    transform translate(0px, 1px)
    box-shadow 0px 2px 0px #44a
  }
}

.fade-text {
  opacity 0.5
}

.doc-article {
  width 100vw
  max-width 100%
  min-height 100vh
  display flex
  flex-direction column
  align-items center
  margin-top $headerHeight
  padding-bottom 70px

  +below(1000px) {
    display block
    padding 0 $horPadding
  }

  &_content {
    width 720px

    +below(900px) {
      width 100% !important
    }

    +below(1600px) {
      width 600px
    }

    &_breadcrumb, &_breadcrumb_collapse {
      color grey
      font-size 0.85rem
      padding 20px 0
      font-weight 100
      font-family myriad-pro-semiextended
    }

    &_body {
      color #222

      line-height, h1 {
        margin 30px 0
        font-size 2.4rem
      }

      br {
        margin 0 0
      }

      strong {
        font-weight 600
      }

      h1, h2, h3, h4 {
        border-bottom solid #ddd 1px
        padding-bottom 0.3em
        font-weight 400
      }

      h1, h2, h3, h4, h5, h6 {
        &:target::before {
          content "";
          display block;
          height 70px;
          margin -70px 0 0;
        }
      }

      h2, h3, h4 {
        margin-top 30px
        margin-bottom 20px
      }

      ul {
        padding-left 20px

        li {
          margin 10px 0
          list-style-type disc
        }

        margin-bottom 1rem
      }

      p {
        margin-bottom 1rem
        line-height 1.85em
      }

      a {
        text-decoration none
      }

      table {
        border-spacing 10px
        border-collapse collapse
        border-radius $borderRadiusSm
        background rgb(245, 245, 245)
        empty-cells hide
        width 100%

        td, th {
          padding 10px 20px

          &:empty {
            display: none;
          }
        }

        td {
          font-weight 100
          color #444

          strong {
            font-weight 400
            color black
          }
        }
        
        tbody {
          tr:not(:last-child) {
            border-bottom solid #ddd 1px
          }
        }
      }

      blockquote {
        background $stone !important
        padding 20px
        margin 10px 0
        font-size 0.9em
        border-radius $borderRadiusSm

        :last-child {
          margin-bottom unset
        }
      }

      mark {
        background-color yellow
      }

      img {
        width 100% !important
        height auto
        margin 10px 0
        border-radius $borderRadiusSm
      }

      pre {
        background #1c1b1b !important
        padding 20px
        border-radius $borderRadiusSm
        margin-bottom 10px
        margin-top 10px
        white-space pre-wrap

        code {
          color #eee
          font-family 'Courier', monospace, sans-serif
          font-size 0.8rem

          * {
            font-family 'Courier', monospace, sans-serif
            font-size 0.8rem
          }
        }
      }
    }
  }
}
</style>