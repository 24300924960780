import * as API from "./api"
import * as Entries from './entries'

export const restructureRootNodes = (docs_tree) => {
  docs_tree.tree = Array.isArray(docs_tree.tree) ? docs_tree.tree : JSON.parse(docs_tree.tree)
  if (!docs_tree.version_slug) {
    docs_tree.version_slug = docs_tree.version
  }
}

export const getFirstDocumentDepthFirst = (root_nodes) => {
  let first_document = undefined
  const getFirstDocumentNodeRecursive = (nodes) => {
    if (nodes && nodes.length > 0) {
      const node = nodes[0]
      if (node.type == 'document') {
        first_document = node
      } else {
        getFirstDocumentNodeRecursive(node.tree)
      }
    }
  }
  getFirstDocumentNodeRecursive(root_nodes)
  return first_document
}

export const getPublishedVersionsWithPublishedDocuments = (docs_section) => {
  const versions_filtered = [...section.versions].filter((x) => {
    const root_nodes = restructureRootNodes(x)
    const first_document = getFirstDocumentDepthFirst(root_nodes)
    x['first'] = first_document
    return first_document !== undefined
  })
  return [...versions_filtered].sort((a, b) => a.version < b.version ? 1 : -1)
}

export const getCategoryMetadata = async (key) => {
  const params = {
    'filters[key]': key,
  }
  const response = await API.get(`/docs-section-category-descriptions`, params)
  const entries = Entries.restructureListResponse(response)
  if (entries && entries.length == 1) {
    return entries[0]
  } else {
    return {
      description: undefined,
      sort_key: key,
    }
  }
}

export const getVisibleSections = (docs_sections) => {
  const visibleSections = []
  for(const element of docs_sections) {
    const section = element
    if (!section.sort_key) {
      section.sort_key = section.name
    }
    const versions_sorted = [...section.versions].sort((a, b) => a.version < b.version ? 1 : -1)
    const latest_version = versions_sorted[0]
    section.latest_version = latest_version
    if (latest_version.tree) {
      restructureRootNodes(latest_version)
      latest_version.first = getFirstDocumentDepthFirst(latest_version.tree)
      if (latest_version.first) {
        visibleSections.push(section)
      }
    }
  }
  return visibleSections
}

export const byCategory = async (docs_sections) => {
  const sectionsWithNoCategory = []
  const categoryLookup = {}
  const categoryList = []
  for (const element of docs_sections) {
    const section = element
    const category = element.category
    if (category) {
      if (category in categoryLookup) {
        categoryLookup[category].push(section)
      } else {
        const sectionsInCategory = [section]
        categoryLookup[category] = sectionsInCategory
        const categoryMetadata = await getCategoryMetadata(category)
        categoryList.push({name: category, sections: sectionsInCategory, description: categoryMetadata.text, sort_key: categoryMetadata.sort_key})
      }
    } else {
      sectionsWithNoCategory.push(section)
    }
  }
  for (const visibleCategory of categoryList) {
    const emphasizedSections = visibleCategory.sections.filter(x => x.emphasize)
    const regularSections = visibleCategory.sections.filter(x => !x.emphasize)
    emphasizedSections.sort((a, b) => a.sort_key.localeCompare(b.sort_key))
    regularSections.sort((a, b) => a.sort_key.localeCompare(b.sort_key))
    visibleCategory.sections = emphasizedSections.concat(regularSections)
  }
  categoryList.sort((a, b) => a.sort_key.localeCompare(b.sort_key))
  
  return {
    sectionsWithNoCategory: sectionsWithNoCategory,
    categories: categoryList,
  }
}

export const flattenCategoryTree = (categories, sectionsWithNoCategory) => {
  let sectionsFlattened = []
  for (const category of categories) {
    sectionsFlattened = sectionsFlattened.concat(category.sections)
  }
  sectionsFlattened = sectionsFlattened.concat(sectionsWithNoCategory)

  const nodes = sectionsFlattened.map(section => ({
      name: section.name,
      slug: section.slug,
      path: `/docs/${section.slug}`,
      type: 'tree',
      tree: [...section.versions.map(version => ({
        name: `📘 ${version.version}`,
        slug: version.version_slug,
        type: 'document',
      }))],
    }))

  return nodes
}