<template>
  <div class="card-layout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardLayout"
}
</script>

<style lang="stylus">
.card-layout {
  margin-top 20px
  margin-bottom 20px
  display flex
  flex-direction column
  flex-wrap wrap
  gap 20px
}
</style>