const pattern = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/

const fallback = {
  beta: -10,
  alpha: -20,
}

const compare = (a, b) => {
  let e_a = pattern.exec(a)
  let e_b = pattern.exec(b)

  if (e_a && e_b) {
    if (e_a[1] === e_b[1]) {
      if (e_a[2] === e_b[2]) {
        e_a = parseInt(e_a[3])
        e_b = parseInt(e_b[3])
      } else {
        e_a = parseInt(e_a[2])
        e_b = parseInt(e_b[2])
      }
    } else {
      e_a = parseInt(e_a[1])
      e_b = parseInt(e_b[1])
    }
  } else {
    if (e_a) {
      e_a = 1
      e_b = -1
    } else if (e_b) {
      e_a = -1
      e_b = 1
    } else {
      e_a = a.toLowerCase()
      e_b = b.toLowerCase()

      if (e_a in fallback && e_b in fallback) {
        e_a = fallback[e_a]
        e_b = fallback[e_b]
      } else if (e_a in fallback) {
        e_a = 1
        e_b = -1
      } else if (e_b in fallback) {
        e_a = -1
        e_b = 1
      } else {
        e_a = 0
        e_b = 0
      }
    }
  }

  return Math.sign(e_b - e_a)
}

const test = (x) => pattern.test(x)

export {
  pattern,
  compare,
  test,
}