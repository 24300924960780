<template>
  <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
    <path d="M200 856V616h60v180h180v60H200Zm500-320V356H520v-60h240v240h-60Z" />
  </svg>
</template>

<script>
export default {
  name: "Expand"
};
</script>