<template>
  <div class="docs">
    <main-header class="main-header-light" :breadcrumbs="['Releases', 'Latest']" />
    <div class="page">
      <h1 class="page-title">📦 <router-link to="/releases">Releases</router-link> <small>/ Latest</small></h1>
      <div class="page-content">
        <h1>Latest Releases</h1>
        <release-list v-if="releasesLoaded" :releases="releases" :data-test="isLoading()" class="card-timeline" />
        <loader v-else />
        <div class="page-cols">
          <div class="page-col"></div>
          <div class="page-col"></div>
          <div class="page-col"></div>
          <div class="page-col"></div>
          <div class="page-col"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "../utils/js/api"
import MainHeader from "../components/UI/MainHeader"
import Loader from "../components/UI/Loader"
import ReleaseList from "../components/releases/ReleaseList"
import * as Entries from "../utils/js/entries"

export default {
  components: {
    MainHeader,
    ReleaseList,
    Loader
  },
  props: {
    vueAppApi: {
      type: String,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      releases: [],
      releasesLoaded: false,
    };
  },
  mounted() { },
  methods: {
    isLoading() {
      console.log('loading')
      console.log(this.releases)
      return true
    },
    async getReleases() {
      const params = {
        'populate[downloads][populate][configuration]': 1,
        'populate[downloads][populate][artifacts]': 1,
        'populate[product][populate][product_group]': 1,
        'populate[product][populate][product_subgroup]': 1,
        'populate[product][populate][docs_section][populate][versions]': 1,
        'populate[product][populate][icon]': 1,
        'populate[product][populate][releases]': 1,
        'pagination[pageSize]': 50,
      }

      const response = await API.getall(`/releases`, params)
      console.log(response)
      const releases = Entries.restructureListResponse(response)

      this.releases = releases
      this.releasesLoaded = true
    }
  },
  created() {
    this.getReleases()
  },
};
</script>