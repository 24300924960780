<template>
  <ul :class="getTreeClass()" :data-tree-depth="getTreeDepth()">
    <li v-for="node in nodes" :id="getNodeId(node)" :class="getNodeClass(node)"  :data-slug="node.slug">
      <div v-if="node.type == 'tree'" class="tree-node-content">
        <div v-if="node.name != '-'" class="tree-node-content-subtree" @click="toggleExpanded(node)" :style="getContentStyle()">
          <div class="tree-node-content-subtree-anchor"></div>
          <span class="subtree-name">{{node.name}}</span>
        </div>
        <tree :nodes="node.tree" :prefix="prefix" :breadcrumbs="getNodePath(node)" :depth="getSubtreeDepth(node)" :class="node.name == '-' ? 'tree-collapse' : ''"/>
      </div>
      <router-link v-else-if="node.type == 'document'" class="tree-node-content tree-node-content-document" tag="a" :to="getNodeFullPath(node)" :style="getContentStyle()">
        <span class="document-name">{{node.name}}</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tree",
  props: ["nodes", "breadcrumbs", "depth", "prefix"],
  data () {
    return {
      expanded: {}
    };
  },
  methods: {
    toggleExpanded(node) {
      const key = this.getNodeId(node)
      const id = this.getNodeId(node)
      const treeElement = document.getElementById(id).querySelector('ul')
      const anchorElement = document.getElementById(id).querySelector('.tree-node-content-subtree-anchor')
      if (key in this.expanded) {
        this.expanded[key] = !this.expanded[key]
      } else {
        this.expanded[key] = true
      }
      if (this.expanded[key]) {
        treeElement.classList.add('expanded')
        anchorElement.classList.add('expanded')
      } else {
        treeElement.classList.remove('expanded')
        anchorElement.classList.remove('expanded')
      }
    },
    getContentStyle() {
      return `padding-left: ${this.getTreeDepth() * 8}px;`
    },
    isTreeActive() {
      return this.$router.currentRoute.path.startsWith(`${this.prefix}/${this.breadcrumbs}/`) ||
      this.$router.currentRoute.path === `${this.prefix}/${this.breadcrumbs}` ||
      this.$router.currentRoute.path === `${this.prefix}/${this.breadcrumbs}/`
    },
    getTreeClass(node) {
      const classList = ['tree']
      if (this.isTreeActive()) {
        classList.push('active')
      }
      return classList.join(' ')
    },
    isNodeActive(node) {
      if (node.type === 'document') {
        return this.$router.currentRoute.path === this.getNodeFullPath(node)
      } else {
        return this.$router.currentRoute.path.startsWith(`${this.getNodeFullPath(node)}/`) ||
        this.isLeafyTree(node)
      }
    },
    isLeafyTree(node) {
      return this.$router.currentRoute.path === this.getNodeFullPath(node) ||
         this.$router.currentRoute.path === `${this.getNodeFullPath(node)}/`
    },
    getNodeClass(node) {
      const classList = ['tree-node']
      if (node.type == 'document') {
        classList.push("tree-node-document")
      } else {
        classList.push("tree-node-subtree")
        if (this.isLeafyTree(node)) {
          classList.push('current')
        }
      }
      if (this.isNodeActive(node)) {
        classList.push("active")
      }
      return classList.join(' ')
    },
    getNodePath(node) {
      if (typeof this.breadcrumbs === 'undefined') {
        return node.slug.replace(/^\//, '')
      } else if (node.slug) {
        return `${this.breadcrumbs}/${node.slug}`
      } else {
        return this.breadcrumbs
      }
    },
    getNodeFullPath(node) {
      const nodePath = this.getNodePath(node)
      if (typeof this.prefix === 'undefined') {
        return nodePath
      } else if (nodePath) {
        return `${this.prefix}/${nodePath}`
      } else {
        return this.prefix
      }
    },
    getTreeDepth() {
      return typeof this.depth === 'undefined' ? 0 : this.depth
    },
    getSubtreeDepth(node) {
      const depth = this.getTreeDepth()
      if (node.slug == '-') {
        return depth
      } else {
        return depth + 1
      }
    },
    getNodeId(node) {
      const key  = this.getNodePath(node)
        .replaceAll(' ', '-')
        .replaceAll('#', 'sharp')
        .replaceAll('+', 'plus')
        .replaceAll('==', 'equals')
        .replaceAll('!=', 'not-equals')
        .replaceAll('++', 'increment')
        .replaceAll('--', 'decrement')
        .replaceAll('/', '_')
        .replaceAll('_-_', '_')
        .toLowerCase();
      
      return `menu--${key}`
    },
  },
};
</script>

<style lang="stylus" scoped>
ul.tree {
  list-style none
  padding-left unset

  margin-bottom unset
  margin-top 20px
  margin-left unset

  li.tree-node-subtree#menu--guides > div > div > span.subtree-name::after {
    content " 🚀"
  }

  li.tree-node-subtree#menu--tutorials > div > div > span.subtree-name::after {
    content " 🎓"
  }

  li.tree-node-subtree#menu--explanations > div > div > span.subtree-name::after {
    content " 👓"
  }

  li.tree-node-subtree#menu--ref > div > div > span.subtree-name::after {
    content " 📖"
  }

  li.tree-node-subtree.active {
    & > .tree-node-content > .tree-node-content-subtree {
      .tree-node-content-subtree-anchor::before {
        content "▾"
      }
    }
  }

  ul.tree{
    margin-top unset
    display none

    &.expanded {
      display block
    }
    &.active {
      display block
    }
    &.tree-collapse {
      display block
    }
  }

  li.tree-node {
    position relative

    .tree-node-content {
      font-weight 200
      width 100%
      transition color 0.2s
    }

    &.tree-node-document {
      position relative
    }

    .tree-node-content-subtree {
      font-weight 400
      font-size 0.92rem
      color #222
      margin-top 0px
      padding-top 5px
      cursor pointer
      transition color 0.2s

      .tree-node-content-subtree-anchor {
        width 0px
        height 100%
        margin-left -4px
        position relative
        
        &::before {
          content "▸"
          position absolute
          left -10px
          top 0
        }
        
        &.expanded::before {
          content "▾"
        }
      }
    }

    &:first-child > div > .tree-node-content-subtree {
      margin-top 0px
    }

    .tree-node-content-document {
      font-weight 200
      font-size 0.92rem
      color black
      text-decoration none
      padding-top 5px
      display inline-block
    }

    ul.tree.tree-collapse {
      padding-left unset
    }
  }
  
  &[data-tree-depth="0"] {
    & > li.tree-node > div.tree-node-content > .tree-node-content-subtree {
      font-weight 400
      font-size 1.1rem
      padding-top 10px
      
      &::before {
        top 2px
      }
    }
  }
}

ul.tree[data-tree-depth="0"]:not(:hover) {
  li.tree-node {
    &.active {
      &.tree-node-document::before {
        content ""
        position absolute
        left - $menuPaddingX
        top 5px
        height 0.85rem * 1.5
        width 2px
        background $active
      }
      & > .tree-node-content-document {
        color $activeDarker
      }
      &.tree-node-subtree.current > .tree-node-content > .tree-node-content-subtree {
        color $active

        &::before {
          content ""
          position absolute
          top 5px
          height 0.85rem * 1.5
          left - $menuPaddingX
          width 2px
          background $active
        }
      }
    }
  }
}

ul.tree[data-tree-depth="0"] > li.tree-node.current > .tree-node-content > .tree-node-content-subtree::before {
  top 10px !important
  height 1rem * 1.5 !important
}

ul.tree[data-tree-depth="0"] > li.tree-node:hover > div.tree-node-content > .tree-node-content-subtree {
  color $active
}

ul.tree:hover {
  li.tree-node {
    .tree-node-content-subtree:hover::before, .tree-node-content-document:hover::before {
      content ""
      position absolute
      top 5px
      height 0.85rem * 1.5
      left - $menuPaddingX
      width 2px
      background $active
    }

    .tree-node-content-subtree:hover {
      color $activeDarker
      
      .tree-node-content-subtree-anchor {
        &::before {
          display block
        }
      }
    }

    .tree-node-content-document:hover {
      color $activeDarker
    }
  }

  li.tree-node:not(:first-child) > .tree-node-content > .tree-node-content-subtree:hover::before {
    top 10px
  }
  
  &[data-tree-depth="0"] {
    & > li.tree-node > .tree-node-content > .tree-node-content-subtree:hover::before,
    .tree-node-content-document:hover::before {
      top 10px
      height 1rem * 1.5
    }
  }
}

.subtree-name, .document-name {
  display inline-block
  max-width 100%
  overflow hidden
  text-overflow ellipsis
  white-space nowrap
}
</style>