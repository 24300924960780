<template>
  <div class="docs">
    <webgl-background />
    <div class="background-fade"></div>
    <main-header class="main-header-light" :breadcrumbs="['Releases']" />
    <div class="docs_success">
      <div class="page">
        <h1 class="page-title">📦 <router-link to="/releases">Releases</router-link> <small v-if="releaseLoaded">/
            <router-link :to="`/releases${product.slug}`">{{ release.product.name }}</router-link> / {{ release.version
            }}</small></h1>
        <div class="page-content" v-if="releaseLoaded">
          <badge :release="release" :src="product.badge" :depth="1"
            :is_legacy="product.is_legacy || (product.product_group && product.product_group.is_legacy)"
            :release_is_clickable="false" />
          <div class="release-info">
            <!-- <pre-release-warning v-if="release.version.startsWith('0')"/> -->
            <release :release="release" :depth="2" :anchors="true" />
            <div v-if="docs_version && docs_version.first">
              <h2 id="docs">📚 Documentation</h2>
              <p>Get started with <router-link :to="`${docs_version.first.path}`" class="doc-link">documentation for
                  this release</router-link>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebglBackground from "../components/home/WebglBackground"
import MainHeader from "../components/UI/MainHeader"
import Release from "../components/releases/Release"
import ReleaseTag from '../components/releases/ReleaseTag.vue'
import ReleaseTitle from "../components/releases/ReleaseTitle"
import Timestamp from "../components/UI/Timestamp.vue"
import Badge from '../components/UI/Badge.vue'
import PreReleaseWarning from '../components/UI/admonitions/PreReleaseWarning.vue'
import * as API from "../utils/js/api"
import * as Entries from "../utils/js/entries"
import * as Docs from "../utils/js/docs"

export default {
  components: {
    WebglBackground,
    MainHeader,
    Release,
    ReleaseTag,
    ReleaseTitle,
    Timestamp,
    Badge,
    PreReleaseWarning,
  },
  data() {
    return {
      docs_version: null,
      product: null,
      release: null,
      releaseLoaded: false,
    };
  },
  mounted() {
    const params = {
      'filters[product][slug][$eq]': `/${this.$route.params.product}`,
      'filters[version][$eq]': `${this.$route.params.version}`,
      'populate[downloads][populate][configuration]': 1,
      'populate[downloads][populate][artifacts]': 1,
      'populate[product][populate][product_group]': 1,
      'populate[product][populate][product_subgroup]': 1,
      'populate[product][populate][docs_section][populate][versions]': 1,
      'populate[product][populate][icon]': 1,
    }
    API.get(`/releases`, params).then(response => {
      this.release = Entries.restructureEntry(response.data.data[0])
      this.product = this.release.product
      const product = this.release.product

      if (product.icon) {
        product.badge = product.icon.url
      } else if (product.devicon) {
        product.badge = `https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${product.devicon}/${product.devicon}-original.svg`
      } else {
        product.badge = undefined
      }

      if (this.product.docs_section && this.product.docs_section.versions.length > 0) {
        const docs_versions = [...this.product.docs_section.versions].filter(docs_section_version => {
          return docs_section_version.version === this.release.version
        })
        if (docs_versions && docs_versions.length > 0) {
          this.docs_version = docs_versions[0]
          if (this.docs_version.tree) {
            Docs.restructureRootNodes(this.docs_version)
            this.docs_version.first = Docs.getFirstDocumentDepthFirst(this.docs_version.tree)
          }
        }
      }
      this.releaseLoaded = true
    })
  },
};
</script>

<style lang="stylus" scoped>
  .page-content {
    h1 {
      pointer-events none

      .release-version {
        color $primary

        svg.icon.icon-inline.release-version-tag path:not([fill="none"]) {
          fill $primary
        }
      }
    }

    .richtext {
      margin-top 1em
    }
  }

  .pre-release-warning {
    margin-top 2em
    margin-bottom -1em
  }
</style>