<template>
    <router-link v-if="clickable"
      tag="a"
      :to="`/releases${release.product.slug}/${release.version}`"
      class="release-version"
      >
      <tag class="icon-inline release-version-tag"/>{{release.version}}
    </router-link>
    <span v-else class="release-version">
      <tag class="icon-inline release-version-tag"/>{{release.version}}
    </span>
</template>

<script>
import Tag from "../icons/Tag.vue";

export default {
  name: "ReleaseTag",
  components: {
    Tag,
  },
  props: {
    release: Object,
    clickable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="stylus">
.release-version {
  text-decoration none !important
  user-select none

  &-tag {
    transform translate(0px, 0.09em)
    
    path:not([fill="none"]) {
      fill $primary
    }
    circle:not([fill="none"]) {
      fill $primary
    }
  }
}
</style>