<template>
  <div class="home">
    <webgl-background />
    <div class="background-fade"></div>
    <main-header class="main-header-home"/>
    <div class="docs_success">
      <div class="page">
        <div class="page-content">
          <div class="home_wrapper">
            <div class="home_wrapper_heading">
              <h1 class="hero_title" ref="title">Haply<br/><small>Developer Hub</small></h1>
              <span class="hero_subtitle" ref="underTitle">
                Start building with touching technology.
              </span>
            </div>

            <card-layout>
              <card-row>
                <card class="card-dark" ref="cardReleases" href="/releases" :clickable="true" :wide="true">
                  <h3>📦 Releases</h3>
                  <p>
                    Download Haply apps and SDK components.&nbsp;
                  </p>
                  <p>
                    <router-link to="/releases/latest">Latest releases feed.</router-link>
                  </p>
                </card>
              </card-row>
              <card-row>
                <card class="card-dark" ref="cardDocs" href="https://docs.haply.co" :clickable="true" :wide="true">
                  <h3>📚 Documentation</h3>
                  <p>Guides, tutorials, deep-dives, and reference material.</p>
                </card>
              </card-row>
              <card-row>
                <card class="card-dark" ref="cardHelpdesk" href="https://www.haply.co/connect/" :clickable="true" :wide="true">
                  <h3>🛎️ Contact</h3>
                  <p><a href="https://www.haply.co/connect" target="_blank">Contact us</a> to submit an inquiry or request support.</p>
                </card>
              </card-row>
            </card-layout>

            <!-- <div ref="afterText" class="home_wrapper_after-text">
              <h2 ref="afterText1"><strong>Inverse3</strong> Evaluation Program</h2>

              <p ref="afterText2">
                To request access to the evaluation program, please <a class="inquiry-link" target="_blank" rel="noopener noreferrer" href="https://www.haply.co/contact-us/">submit an inquiry</a>.
              </p>
            </div> -->
            <p></p>
          </div><!--home_wrapper-->
        </div>
      </div>
    </div>
    <!-- <div class="home_form-text">
      <p></p>
    </div> -->
  </div>
</template>

<script>
import WebglBackground from "../components/home/WebglBackground";
import MainHeader from "../components/UI/MainHeader";
import Loader from "../components/UI/Loader";
import UIAnimations from "@/utils/js/UIAnimations";
// import ElementMagnet from "../classes/ElementMagnet";
import Card from "../components/UI/Card.vue";
import CardRow from "../components/UI/CardRow.vue";
import CardLayout from "../components/UI/CardLayout.vue";
import OpenInNew from "../components/icons/OpenInNew.vue";

export default {
  name: "Home",
  components: {
    WebglBackground,
    MainHeader,
    Card,
    CardRow,
    CardLayout,
    Loader,
    OpenInNew,
  },
  mounted() {
    let delay = 100
    UIAnimations.popup(this.$refs.title, delay += 100)
    UIAnimations.popup(this.$refs.underTitle, delay += 100)
    UIAnimations.popup(this.$refs.cardReleases, delay += 100)
    UIAnimations.popup(this.$refs.cardDocs, delay += 100)
    UIAnimations.popup(this.$refs.cardForum, delay += 50)
    UIAnimations.popup(this.$refs.cardHelpdesk, delay += 50)
    // UIAnimations.popup(this.$refs.afterText, delay += 50)
  },
  computed: {
    getDocArticles() {
      if (!this.$store.state.docsTree[0]) return;
      let docTree = this.$store.state.docsTree;
      docTree.sort((a, b) => {
        return parseFloat(b.name) - parseFloat(a.name);
      });
      this.$store.state.currVersion = docTree[0].name;

      return docTree;
    }
  },
  methods: {
    onVersionChange() {
      this.$store.state.currVersion = this.$refs.versionSelect.value;
    },
    getStarted() {
      this.$router.push(
        `/documentation/0.0.1--csharp-reference--inverse3connection--type-definition`
      );
    }
  }
};
</script>

<style lang="stylus" scoped>
.background-fade, .webgl-background {
  opacity 1
}

.page {
  background #fafafa00
}
</style>

<style lang="stylus">
.no-underline {
  text-decoration none !important
}

$gutterHeight = 75px

.home {
  width 100vw
  max-width 100%
  min-height 100vh
  display flex
  justify-content center
  align-items flex-start
  color white
  background black
  padding-top $headerHeight
  padding-bottom $gutterHeight
  position relative

  .page {
    headerHeight = $headerHeight
    gutterHeight = $gutterHeight
    min-height "calc(100vh - %s - %s)" % (headerHeight gutterHeight)
    padding-top unset
  }

  &_form-text {
    padding 0 8px
  }

  &_wrapper {
    position relative
    max-width 520px
    
    &_heading {
      .hero_title {
        width 100%
        text-align center
        font-size 5rem
        line-height 150%
        margin-top 20px
        margin-bottom 20px
        color #ffffffff
        font-weight 600

        small {
          font-weight 200
          color white
          display block
          margin-top -0.5em
          font-size 0.75em
        }
      }

      .hero_subtitle {
        width 100%
        text-align center
        color #eee
        font-weight 200
        display inline-block
        font-size 1.5em
        font-style italic
        line-height 100%
        margin-bottom 20px
      }

      h2 {
        text-align center
        font-weight 500
        color #aaa
        margin-bottom 50px
      }

      h3 {
        color #ddd
      }
    }

    p {
      text-align left
      margin-bottom 20px
      text-shadow 0 0 4px black
    }

    &_after-text {
      $afterTextFade = rgba(1,2,4,0.267)
      margin-top 75px
      padding 20px 30px
      border-radius 50px
      background linear-gradient(to bottom, alpha($afterTextFade, 0%), $afterTextFade)
      box-shadow 0px 5px 10px $afterTextFade
      border-bottom 1px solid rgba(221,238,255,0.533)
      text-shadow 0 2px 12px #000c
      font-size 1.25em

      *:not(strong) {
        font-weight 200
      }

      h2 {
        font-size 1.35em
        text-align center
      }

      p {
        color #ffffffee
        text-align center
      }
    }

    &_dl-links {
      margin-bottom 20px
      padding-left 0px

      li {
        margin 10px 0
        text-shadow 0 0 4px black
      }
    }

    h2, p {
      margin-top 10px
      margin-bottom 10px
    }
  }

  &_form-text {
    & * {
      font-weight 200
    }
    position absolute
    bottom 10px
    left 0
    width 100%
    display flex
    justify-content center
    color #ccc
    font-size 0.9rem

    a {
      text-decoration-thickness: from-font;
    }

    a:hover {
      color $active
    }
  }
}

.docs-link {
  &::after {
    content ""
  }
}

.docs-link-primary {
  margin-bottom 0.5em
}

&release-link {
  
}

&release-more {
  text-align right
}

a.inquiry-link {
  font-weight 400
}

.home_wrapper_after-text {
  p {
    color white !important
  }
}
</style>
