<template>
<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  height="24"
  viewBox="0 -960 960 960"
  width="24">
  <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" fill="#000000" />
</svg>
</template>

<script>
export default {
  name: "ChevronLeftIcon",
};
</script>
