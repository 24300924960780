<template>
  <table class="versions nostyle">
    <thead></thead>
    <tbody>
      <tr v-for="release in series.all" class="release">
        <td class="version" :class="{ prever: release.is_pre }">
          <router-link :to="`/releases${release.product.slug}/${release.version}`">
            <span class="number">{{ release.version }}</span>
            <span class="latest" v-if="release === release.product.latest_release"> (latest)</span>
          </router-link>
        </td>
        <td class="version-info">
          <router-link :to="`/releases${release.product.slug}/${release.version}#downloads`" class="download-link"
            v-if="release.downloads && release.downloads.length > 0">
            download
          </router-link>
          <router-link :to="`/releases${release.product.slug}/${release.version}#changelog`" class="changelog-link"
            v-if="release.changelog">
            changelog
          </router-link>
          <router-link :to="`/releases${release.product.slug}/${release.version}#notes`" class="notes-link"
            v-if="release.notes">
            notes
          </router-link>
          <router-link :to="`/releases${release.product.slug}/${release.version}#docs`" class="docs-link"
            v-if="release.docs_version && release.docs_version.first">
            docs
          </router-link>
        </td>
        <td class="version-timestamp">
          <span>{{ release.createdAtRelativeDate }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "VersionTable",
  props: ["series"],
  methods: {
    reduceConfiguration(release) {
      if (release.downloads) {
        const tags = []
        for (const download of release.downloads) {
          for (const pair of download.configuration) {
            const tag = pair.value.toLowerCase()
            if (!(tags.includes(tag))) {
              tags.push(tag)
            }
          }
        }
        return tags
      } else {
        return []
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.versions {
  table-layout auto
  width 100%

  border-collapse collapse
  border none
  padding 0
  margin 0

  thead, tbody, tr, td, th {
    border-collapse collapse
    vertical-align baseline
    border none
    padding 0
    margin 0
    background unset
  }

  td:not(:first-child) {
    padding-left 8px
  }

  .release {
    font-weight 200
    color $secondaryAccent

    .version {
      min-width fit-content
      white-space nowrap
      color $primary

      a {
        text-decoration none
      }
      
      .number {
        font-size 1.2rem
        font-weight 400
      }
    }

    .prever {
      color $secondary
      font-style italic
    }

    .version-info {
      width 100%
      text-align left

      > a:not(:last-child) {
        margin-right 8px
        position relative

        &::after {
          position absolute
          right -0.2em
          bottom -0.05em
          content ","
          text-decoration none
        }
      }
    }

    .version-timestamp {
      min-width fit-content
      white-space nowrap
      text-align right
    }

    a {
      display inline-block

      &:hover {
        color $active !important
      }
    }
  }
}
</style>