import * as THREE from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

class DarkWingArms {
  constructor() {

  }

  init(scene) {
    this.scene = scene
    this.modelLoader = new GLTFLoader()
    this.texLoader = new THREE.TextureLoader()
    this.armScene
    this.outerRotator
    const nylonMatCap = this.texLoader.load("/assets/3d/textures/dwMatCap.png")
    const metalMatCap = this.texLoader.load("/assets/3d/textures/dwMetalMatCap.png")

    this.modelLoader.load('/assets/3d/models/DarkWingArms.glb', (glb) => {
      this.armScene = glb.scene
      this.armScene.translateX(.3)
      this.armScene.rotateZ(-.3)
      this.armScene.traverse(child => {
        if (child instanceof THREE.Mesh)
          if (child.name == "outerBearing")
            child.material = new THREE.MeshMatcapMaterial({
              matcap: metalMatCap
            })
          else
            child.material = new THREE.MeshMatcapMaterial({
              matcap: nylonMatCap
            })

        if (child.name == 'outerRotator')
          this.outerRotator = child

      })
      this.scene.add(this.armScene)
    })
  }

  update() {
    if (!this.armScene)
      return
    this.armScene.rotateX(0.003)
    this.outerRotator.rotation.y = Math.sin(Date.now() * 0.0002) * 0.4 + 0.2
  }
}


const _instance = new DarkWingArms
export default _instance