<template>
  <div class="docs">
    <main-header />
    <div class="docs_loader" v-if="!productLoaded">
      <Loader />
    </div>
    <div class="docs_success" v-if="getProduct">
      <div class="docs_success_loaderWrapper" v-if="!productLoaded">
        <loader />
      </div>
      <div v-else>
        <h1>{{product.name}}</h1>
        <p v-if="product.description">{{product.description}}</p>
        <p v-if="product.latest_release">{{product.latest_release.createdAt}}</p>
      </div>
    </div>
    <div class="docs_fail" v-else>
      <h1>Bad request URL</h1>
      <h2>This documentation end point does not exist</h2>
    </div>
  </div>
</template>

<script>
import MainHeader from "../components/UI/MainHeader";
// import SidebarMenu from "../components/docs/SidebarMenu";
// import DocArticle from "../components/docs/DocArticle";
// import ArticleParts from "../components/docs/ArticleParts";
import Loader from "../components/UI/Loader";

// import slugify from "../utils/js/slugify";

export default {
  components: {
    MainHeader,
    Loader,
  },
  data() {
    return {
      group: null,
      groupLoaded: false,
    };
  },
  mounted() {},
  computed: {
    getProduct() {
      const key = `/${this.$route.params.group}/${this.$route.params.product}`
      const groupId = this.$store.state.groupPaths[key]
      if (productId) {
        const group = this.$store.state.groups.filter( g => g.id == groupId )[0]
        this.group = group
        this.groupLoaded = true
        return true
      } else {
        return false
      }
    }
  },
  created() {}
};
</script>

<style lang="stylus" scoped>
.docs {
  &_loader {
    position absolute
    top 0
    left 0
    width 100vw
    max-width 100%
    height 100vh
    display flex
    justify-content center
    align-items center
    background white
  }

  &_success {
    &_loaderWrapper {
      width 100vw
      max-width 100%
      height 100vh
      position absolute
      z-index 1
      top 0
      left 0
      background white
      display flex
      justify-content center
      align-items center
    }
  }

  &_fail {
    width 100vw
    max-width 100%
    height 100vh
    display flex
    flex-direction column
    justify-content center
    align-items center
  }
}
</style>