<template>
<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  height="24"
  viewBox="0 -960 960 960"
  width="24">
  <path
    d="M480-120 300-300l58-58 122 122 122-122 58 58-180 180ZM358-598l-58-58 180-180 180 180-58 58-122-122-122 122Z"
    fill="#000000" />
</svg>
</template>

<script>
export default {
  name: "UnfoldMoreIcon",
};
</script>
