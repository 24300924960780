<template>
  <div class="webgl-backround" ref="glBackground">
    <!-- <div class="webgl-backround_container" ref="glContainer"></div> -->
    <img ref="image" src="/assets/images/teaser.jpg" alt />
  </div>
</template>

<script>
import MainThreeScene from "../../classes/WebGL/MainThreeScene";
import ImageParalax from "../../classes/ImageParalax";

import UIAnimations from "@/utils/js/UIAnimations";

export default {
  name: "WebglBackground",
  mounted() {
    // new ImageParalax(this.$refs.image);
    // UIAnimations.popup(this.$refs.glBackground, 900);
  }
};
</script>

<style lang="stylus">
.webgl-backround {
  position absolute
  top 0
  left 0
  width 100vw
  height 100%
  max-width 100%
  overflow hidden

  img {
    object-fit cover
    object-position center
    width 150%
    height 150%
  }

  canvas {
    max-width 100%
  }
}
</style>