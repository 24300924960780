<template>
  <div class="docs">
    <main-header class="main-header-light" :breadcrumbs="['Docs']"/>
    <div class="docs_success">
      <div class="page with-sidebar with-outline">
        <h1 class="page-title">📚 <router-link to="/docs">Docs</router-link><small v-if="tree"><span v-if="section"> / <router-link :to="`../${section.slug}`">{{section.name}}</router-link><span v-if="tree"> / {{tree.version}}</span></span></small></h1>
        <div class="page-sidebar docs">
          <div class="page-sidebar-content">
            <div v-if="section" class="section-title">{{section.name}}</div>
            <div class="version-select">
              Version
              <select v-if="section && tree" class="version-select-dropdown">
                <template v-for="version in section.versions">
                  <option v-if="version.version === tree.version" selected>
                    📘 {{ version.version }}
                  </option>
                  <option v-else>📘 {{ version.version }}</option>
                </template>
              </select>
            </div>
            <tree v-if="tree && section" :nodes="getSidebarTree()" :prefix="`/docs/${section.slug}/${tree.version_slug}`"/>
          </div>
        </div>
        <div class="page-content" ref="page_content">
          <div v-if="section && tree">
            <h1>{{ section.name }} <span class="section-version">{{ tree.version }} Documentation</span></h1>
            <p v-if="tree.description">
              {{ tree.description }}
            </p>
            <p v-else>
              This is an overview of the documentation available for the
              <template v-if="isSemver(tree.version)">version {{ tree.version }}</template>
              <template v-else>{{ tree.version }} version</template>
              of the {{ section.name }}.
            </p>
            <p v-if="section.versions.length > 1 ">For a list of all available versions, go <router-link :to="`/docs/${section.slug}`">here</router-link>.</p>
            <template v-if="tree.tree.filter(x => x.type === 'document' && x.featured).length > 0">
              <card-layout>
                <card-row>
                  <card v-for="node in tree.tree" :key="node.slug"
                    v-if="node.type == 'document' && node.featured"
                    :clickable="true"
                    :href="node.path"
                    class="tree-node-content tree-node-content-document featured"
                    >
                    <h3><span class="document-name">{{ node.name }}</span></h3>
                  </card>
                </card-row>
              </card-layout>
            </template>
            <template v-for="node in tree.tree">
              <template v-if="node.type == 'tree'">
                <h2 :id="node.slug">
                  <router-link :to="`/docs/${section.slug}/${tree.version_slug}/${node.slug}`">
                    {{ node.name }}
                  </router-link>
                </h2>
                <card-layout>
                  <card-row>
                    <card v-for="child in node.tree" :key="child.slug"
                      v-if="child.type == 'document'"
                      :clickable="true"
                      :href="child.path"
                      :class="`tree-node-content tree-node-content-document${child.featured ? ' featured' : ''}`"
                      :style="child.featured ? '' : 'background: white;'"
                      >
                      <h3><span class="document-name">{{ child.name }}</span></h3>
                    </card>
                  </card-row>
                </card-layout>
                <p class="info" v-if="getQuadrant(node.slug) === 'how-to-guides'">
                  <icon-inline><info-icon/></icon-inline> You can read more about what constitutes a guide <a href="https://diataxis.fr/how-to-guides/" target="_blank" rel="noopener noreferrer">here</a>.
                </p>
                <p class="info" v-else-if="getQuadrant(node.slug) === 'tutorials'">
                  <icon-inline><info-icon/></icon-inline> You can read more about what constitutes a tutorial <a href="https://diataxis.fr/tutorials/" target="_blank" rel="noopener noreferrer">here</a>.
                </p>
                <p class="info" v-else-if="getQuadrant(node.slug) === 'explanation'">
                  <icon-inline><info-icon/></icon-inline> You can read more about what constitutes an explanation <a href="https://diataxis.fr/explanation/" target="_blank" rel="noopener noreferrer">here</a>.
                </p>
                <p class="info" v-else-if="getQuadrant(node.slug) === 'reference'">
                  <icon-inline><info-icon/></icon-inline> You can read more about what constitutes reference material <a href="https://diataxis.fr/reference/" target="_blank" rel="noopener noreferrer">here</a>.
                </p>
              </template>
            </template>
            <template v-if="tree.tree.filter(x => x.type === 'document' && !x.featured).length > 0">
              <h2>More</h2>
              <card-layout>
                <card-row>
                  <card v-for="node in tree.tree" :key="node.slug"
                    v-if="node.type == 'document' && !node.featured"
                    :clickable="true"
                    :href="node.path"
                    style="background: white;"
                    class="tree-node-content tree-node-content-document"
                    >
                    <h3><span class="document-name">{{ node.name }}</span></h3>
                  </card>
                </card-row>
              </card-layout>
            </template>
          </div>
          <loader v-else/>
        </div>
        <div class="page-outline">
          <div class="page-outline-content">
            <h2>On this page 📃</h2>
            <outline v-if="parts" :parts="parts"/>
            <div v-else>Loading</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReleaseTag from "../components/releases/ReleaseTag"
import Timestamp from "../components/UI/Timestamp"
import MainHeader from "../components/UI/MainHeader"
import Loader from "../components/UI/Loader"
import Card from "../components/UI/Card.vue"
import CardRow from "../components/UI/CardRow.vue"
import CardLayout from "../components/UI/CardLayout.vue"
import Outline from "../components/docs/Outline"
import Tree from "../components/docs/Tree.vue"
import IconInline from '../components/UI/IconInline.vue'
import InfoIcon from "../components/icons/InfoIcon.vue"
import OutlineIcon from '../components/icons/OutlineIcon.vue'
import UnfoldLessIcon from '../components/icons/UnfoldLessIcon.vue'
import UnfoldMoreIcon from '../components/icons/UnfoldMoreIcon.vue'
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon.vue'
import ChevronRightIcon from '../components/icons/ChevronRightIcon.vue'
import * as API from "../utils/js/api"
import * as Entries from "../utils/js/entries"
import * as Docs from "../utils/js/docs"
import * as Diataxis from "../utils/js/diataxis"
import slugify from "../utils/js/slugify"
import * as Semver from "../utils/js/semver"
import sleep from "../utils/js/sleep"

export default {
  components: {
    MainHeader,
    Loader,
    ReleaseTag,
    Timestamp,
    Card,
    CardRow,
    CardLayout,
    Outline,
    Tree,
    IconInline,
    InfoIcon,
    OutlineIcon,
    UnfoldLessIcon,
    UnfoldMoreIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      section: null,
      tree: null,
      parts: null,
    };
  },
  mounted() {},
  methods: {
    getSidebarTree() {
      return [
        {
          'name': 'Contents',
          'slug': '',
          'path': `/docs/${this.section.slug}/${this.tree.version_slug}`,
          'type': 'document',
        },
        ...this.tree.tree
      ]
    },
    isSemver(version) {
      return Semver.test(version)
    },
    getQuadrant(slug) {
      return Diataxis.getQuadrant(slug)
    },
    getBreadcrumbs(tree, document) {
      const slugs = document.path.split('/')
      const breadcrumbs = []
      let node = tree
      let i = 2
      for(; i < slugs.length; i++) {
        for(const element of node.tree) {
          if (element.slug == slugs[i]) {
            node = element
            breadcrumbs.push(node.name)
            break
          }
        }
      }
      return breadcrumbs.filter(bc => bc !== '-')
    },
    async getSection() {
      const params = {
        'filters[slug]': this.$route.params.section,
        'populate[versions]': '1',
        'populate[product][populate]': '*',
      }

      const response = await API.get(`/docs-sections`, params)
      const section = Entries.restructureEntry(response.data.data[0])

      for(const version of section.versions) {
        Docs.restructureRootNodes(version)
      }

      this.section = section
      sleep(1000)
    },
    async getTree() {
      // query the tree from from Strapi
      const params = {
        'filters[section][slug]': this.$route.params.section,
        'filters[version_slug]': this.$route.params.version,
        'populate[section]': '1',
      }

      const response = await API.get(`/docs-trees`, params)

      // restructure the tree response
      const tree = Entries.restructureEntry(response.data.data[0])

      Docs.restructureRootNodes(tree)

      this.tree = tree
      sleep(1000)
    },
    async resolve () {
      Promise.all([
        this.getSection(),
        this.getTree(),
      ]).then(result => {
        this.getParts()
      })
    },
    async getParts() {
      const content = this.$refs.page_content
      let headings = content.querySelectorAll('h1, h2');
      let parts = []
      for (const heading of headings) {
        heading.id = slugify(heading.textContent)
        parts.push({
          text: heading.textContent,
          level: heading.tagName.substring(1),
          anchor: heading.id,
        });
      }

      parts[0].text = 'About'

      this.parts = parts
    }
  },
  created() {
    this.resolve()
  },
  watch: {
    '$route' (to, from) {
      if(to.path !== from.path) {
        this.resolve()
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
.page-sidebar-content {
  .section-title {
    font-size 1.15em
    margin-bottom 0.5em
  }

  .version-select {
    font-size 0.9rem
    font-weight 200

    .version-select-dropdown {
      font-size 0.9rem
      font-weight 200
    
      background none
      border none

      option {
        font-size inherit
        font-weight inherit
        font-family "myriad-pro", sans-serif
      }
    }
  }
}

.page-content {
  .section-version {
    font-weight 200
  }

  h2 {
    a {
      text-decoration none
    }

    &#guides::after {
      content " 🚀"
    }

    &#tutorials::after {
      content " 🎓"
    }

    &#explanations::after {
      content " 👓"
    }

    &#ref::after {
      content " 📖"
    }
  }

  .tree-node-content-document {
    h3 {
      font-size 1rem

      .document-name::before {
        content: '📄 '
      }
    }

    &:not(.featured) {
      h3 {
        font-weight 200
      }
    }
  }
}
</style>