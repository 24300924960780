import axios from 'axios';

const get = (url, params) => {
  const base = process.env.VUE_APP_API_BASE

  params = params ? params : {}

  if (process.env.VUE_APP_PUBLICATION_STATE) {
    params.publicationState = process.env.VUE_APP_PUBLICATION_STATE
  }

  return axios.get(`${base}${url}`, { params })
}

const getall = async (url, params) => {
  // make sure to get all pages if meta pagecount is more than 1
  // pagination[page]=1&pagination[limit]=100
  let response = await get(url, params)
  console.log(response.data.meta)
  let data = response.data
  while (response.data.meta.pagination.pageCount > response.data.meta.pagination.page) {
    console.log('fetching page', response.data.meta.pagination.page + 1)
    params['pagination[page]'] = response.data.meta.pagination.page + 1
    response = await get(url, params)
    data = { ...data, data: [...data.data, ...response.data.data] }
  }
  response.data = data
  return response
}

export {
  get, getall
}