<template>
  <div id="app">
    <!-- <main-header /> -->
    <router-view />
    <main-footer />
  </div>
</template>
<script>
import MainHeader from "./components/UI/MainHeader";
import MainFooter from "./components/UI/MainFooter";

export default {
  components: {
    MainHeader,
    MainFooter
  },
  created() {
    this.$store.dispatch("fetchAll");
  }
};
</script>

<style lang="stylus">
  
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&family=Lato:wght@100;300;400;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600;700&display=swap')

* {
  box-sizing border-box
}

body {
  margin 0
  font-family 'myriad-pro', sans-serif
}

.webgl-background {
  opacity 0
}

.background-fade {
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background linear-gradient(180deg, #0000, #8af4)
  opacity 0
  transition opacity 0.5s
}

h1, h2, h3, h4, h5, h6 {
  margin-top 1em
  margin-bottom 0.25em
  font-weight 400
}

strong {
  font-weight 600
}

p {
  font-weight 200
  line-height 1.6rem
}

a {
  //text-decoration none
  color inherit
}

ul, ol {
  margin-left 10px
}

br {
  display block
  content ''
}

select {
  position relative
  border none
  border-radius $borderRadiusSm
  border-right 4px transparent solid
}

.page-title {
  margin-top unset
  margin-bottom unset
}

.richtext, .page-content, .page-outline-content {
  * {
    text-decoration-thickness from-font !important
  }

  a {
    text-decoration underline
  
    &:hover {
      color $active
      svg.icon *:not([fill="none"]) {
        fill $active
      }
    }
  }

  .card.featured {
    background-color $featured

    &:hover {
      &::before {
        border-color $featured
      }
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight 400
      color white
    }

    a {
      svg.icon *:not([fill="none"]) {
        fill white
      }
      
      &:hover {
        color white
        
        svg.icon.icon-inline {
          *:not([fill="none"]) {
            fill $activeOnPrimary
          }
        }
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    scroll-margin-top $headerHeight + $pageTitleHeight + 20px
  }

  ul, ol, p {
    font-weight 200
    line-height: 1.5
    margin 0.5em 0
  }

  ul, ol {
    padding-left 24px
    margin-left 0px
  }

  svg.icon, img.icon {
    fill $secondaryAccent
    height 1.2em
    transform translate(0, 0.09em)
    margin unset
  }

  .img-wrapper, pre {
    margin 2em 0
  }

  table:not(.nostyle) {
    margin 1em 0
  }

  .img-wrapper {
    background $secondaryMedium
    border-radius 4px
    max-width 100%
    width fit-content
    margin-left auto
    margin-right auto
    display flex
    align-items center
    justify-content center
    box-shadow 0px 4px 8px #0001

    img:not(.icon) {
      max-width 100%
      border-radius 4px
    }
  }

  &.compact {
    font-size 0.9em

    .img-wrapper, table:not(.nostyle), pre {
      margin 1em 0
    }
  }

  blockquote.admonition {
    margin 8px 0
    padding 4px 8px 4px 8px
    padding-right 16px
    border-radius $borderRadiusSm
    background #eeeeee
    color #888
    transition outline 0.2s ease-in-out

    &[class*=" admonition-type--"] {
      position relative
      padding-left "calc(2em + %s)" % $admonitionBannerWidth

      &::before {
        position absolute
        left 4px + $admonitionBannerWidth
        display: flex;
        width: 1.4em;
        justify-content: center;
      }

      &::after {
        content " "
        position absolute
        left 0
        top 0
        bottom 0
        border-radius inherit
        border-top-right-radius 0
        border-bottom-right-radius 0
        overflow hidden
        width 4px
      }
    }
    
    &.admonition-type--note {
      background #e0eefdaa
      
      p {
        color #16789f
      }
      
      &::before {
        content "📝"
        text-shadow $admonitionEmojiShadow #16789fa0
      }

      &::after {
        background #16789f80
      }
    }

    &.admonition-type--important {
      background #ede0f2aa
      
      p {
        color #8d3499
      }

      &::before {
        content "🛎️"
        text-shadow $admonitionEmojiShadow #8d3499a0
      }

      &::after {
        background #8d349980
      }
    }

    &.admonition-type--caution {
      background #ffeeeeaa
      
      p {
        color #ba2525
      }

      &::before {
        content "❗"
        text-shadow $admonitionEmojiShadow #b74141a0
      }

      &::after {
        background #c7171780
      }
    }
    
    &.admonition-type--warning {
      background #ffe5a680
      
      p {
        color #c26009
      }

      &::before {
        content "🚧"
        text-shadow $admonitionEmojiShadow #c8600ca0
      }

      &::after {
        background #e1700e99
      }
    }

    &.admonition-type--tip {
      background #dbf2e7aa
      
      p {
        color #2f7b5f
      }
      
      &::before {
        content "💡"
        text-shadow $admonitionEmojiShadow #2f7b5fa0
      }

      &::after {
        background #2f7b5f80
      }
    }

    > p:first-child {
      margin-top unset
    }

    > p:last-child {
      margin-bottom unset
    }
  }

  &.compact {
    blockquote.admonition {
      margin 6px 0
    }
  }

  blockquote:not(.admonition) {
    border-left 4px solid $secondaryLight
    margin-left 12px
    padding-left 12px
    color $blockquote
  }

  code:not(.grvsc-code) {
    display inline-block
    background $secondaryLight
    border-radius 0.6em
    padding 0 0.5em
    color $secondaryAccent
    font-weight 400
    font-family monospace
  }

  code.grvsc-code {
    width 100%

    span.grvsc-line {
      &:hover {
        background alpha($active, 20%)
        * {
          filter brightness(105%)         
        }
      }
    }
  }

  pre.grvsc-container {
    position relative

    div.grvsc-copy {
      display none
      position absolute
      top 10px
      right 10px
      width 26px
      height 30px
      border-radius $borderRadiusSm
      background white
      box-shadow 0px 2px 4px #0002
      
      svg {
        pointer-events none
        touch-action none

        width 100%
        height 100%

        *:not([fill="none"]) {
          fill $primary
        }
      }

      &:hover {
        svg {
          *:not([fill="none"]) {
            fill $active
          }
        }
      }

      &:active {
        background $active
        
        svg {
          *:not([fill="none"]) {
            fill white
          }
        }
      }
    }

    &:hover {
      div.grvsc-copy {
        display block
      }
    }
  }

  table:not(.nostyle) {
    border-collapse collapse
    overflow hidden
    width 100%

    thead {
      tr {
        th {
          font-weight 400
        }
      }
    }

    th, td {
      padding 10px 10px
      text-align left
    }

    td {
      border-top 1px solid #bbb
      font-weight 200
    }

    tr:first-child td {
      border-top-width 2px
    }

    tr:hover {
      td {
        background alpha($active, 0.075)
        color lightness($active, 40%)
        border-top-color $active
      }
    }
  }
}

.icon-inline {
  width 0.8em
  height 0.8em
  max-width 0.8em
  max-height 0.8em
}

.linear-wipe {
  text-align: center
  
  background: linear-gradient(110deg, #FFF 20%, $lavender 40%, $lavender 60%, #FFF 80%)
  background-size: 200% auto
  
  color: #000
  background-clip: text
  text-fill-color: transparent
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  
  animation: shine 2s linear infinite
  @keyframes shine {
    to {
      background-position: -200% center
    }
  }
}

.page {
  transition background 0.5s
}

.docs_loader {
  position absolute
  top 0
  left 0
  width 100vw
  max-width 100%
  height 100vh
  display flex
  justify-content center
  align-items center
  background white
}

.docs_success {
  &_loaderWrapper {
    width 100vw
    max-width 100%
    height 100vh
    position absolute
    z-index 1
    top 0
    left 0
    background white
    display flex
    justify-content center
    align-items center
  }
}

.docs_fail {
  width 100vw
  max-width 100%
  height 100vh
  display flex
  flex-direction column
  justify-content center
  align-items center
}

.page {
  position relative
  padding-top $headerHeight + $pageTitleHeight
  min-height 100vh
  background $mainBgLight
  display flex
  flex-direction row
  flex-wrap nowrap
  justify-content center
  gap $mainContentGap
}

.page-content {
  flex-grow 0
  padding-top $mainContentOffset
  padding-left 10px
  padding-right 10px
  padding-bottom 50px
  width 100%
  max-width $mainContentWidth

  :not(blockquote.admonition):not(code) > {
    p, ul, ol, h3, h4, h5, h6 {
      color #444
    }
  }

  & > .richtext {
    :not(blockquote):not(pre):not(.card) {
      color #444
    }
  }

  .info {
    color $secondaryDarker
    font-size 0.9em
    font-weight 200

    svg.icon {
      fill alpha($secondaryDarker, 80%)
    }
  }

  footer {
    margin-top $mainContentGap
    border-top 1px solid #ddd
    color #444
  }

  :not(.card-content) {
    && > h2 {
      font-size 1.6rem
    }
    && > h3 {
      font-size 1.3rem
    }
  }
}

.columns {
  display flex
  flex-direction row
  gap $mainContentGap
  width 100%
  justify-content space-between
  flex-wrap wrap

  .column {
    display block
    width fit-content
  }
}

.page-sidebar {
  flex-grow 1
  flex-direction row
  justify-content flex-end
  min-width $menuWidth
  padding-top $mainContentOffset
  display none

  &.expanded {
    display: flex;
  }

  &.docs {
    background linear-gradient(270deg, #fff, #fff0)
    box-shadow 0px 0px 10px #0001
    margin-right 50px
  }

  .page-sidebar-content {
    width $menuWidth
    min-height 100px
    height fit-content
    border-left 1px solid #ddd
    padding $menuPadding
  }

  :first-child {
    margin-top 0
  }

  @media (max-width: 1149.9px) {
    position: fixed;
    left: 0;
    bottom: 0px;
    top: $headerHeight + $pageTitleHeight;
    overflow-y: scroll;

    &.docs {
      background: white;
    }
  }
}

.page-outline {
  flex-grow 2
  flex-direction row
  justify-content flex-start
  min-width $menuWidth
  display none

  .page-outline-content {
    position fixed
    top $headerHeight + $pageTitleHeight + $mainContentOffset
    width $menuWidth
    height fit-content
    min-height 100px
    border-left 1px solid #ddd
    padding $menuPadding
  }

  :first-child {
    margin-top 0
  }
  
  :last-child {
    margin-bottom 0
  }
}

@media screen and (min-width: 1150px) {
  .page.with-outline:not(.with-sidebar) {
    padding-left $mainContentGap + $menuWidth
  }
  .page-sidebar {
    display flex
  }
  +below(1500px) {
    .page.with-sidebar.with-outline {
      padding-right calc(100% - (760px + 300px + 50px + 20px + 20px))
    }
  }
}

@media screen and (min-width: 1500px) {
  .page.with-sidebar:not(.with-outline) {
    padding-right $mainContentGap + $menuWidth
  }
  .page-outline {
    display flex
  }
}

.page-outline-content {
  p, ul, ol {
    line-height 1.15
  }
  
  & > h2 {
    font-size 1.15rem
  }
  & > h3 {
    font-size 1.15rem
  }
}

.page-title {
  position fixed
  top $headerHeight
  left 0px
  right 0px
  min-height $pageTitleHeight
  height fit-content
  padding 10px 30px
  text-align left
  font-size 1.25rem
  background white
  z-index 2
  box-shadow 0px 0px 10px #0001

  small {
    font-weight 200
  }

  a {
    text-decoration none

    &:hover {
      color $active
    }

    svg.icon /deep/ *:not([fill="none"]) {
      fill $active
    }
  }
}


.collapse:last-child:not(.expanded) {
  > .collapse-foldout {
    margin-bottom 0px
  }
}   

.collapse-foldout {
  transition margin-bottom 0.4sec ease-in-out
} 

.collapse {
  &:first-child {
    margin-top 16px
  }
  
  .collapse-foldout {
    margin-top 0
    cursor pointer

    &::after {
      margin-left 0.5em
      content "›"
      transition transform 0.2s ease-in-out
      transform-origin 50% 60%
      display inline-block
    }

    &:hover {
      color $active
    }
  }
  
  .collapse-content {
    overflow-y hidden
    opacity 0
    max-height 0px
    transition opacity 0.2s ease-in-out

    > :first-child {
      margin-top 0
    }
    > :last-child {
      margin-bottom 0
    }
  }

  &:not(:last-child) {
    margin-bottom 0px
    transition margin-bottom 0.2s ease-in-out
  }

  &.expanded {
    margin-bottom 16px

    .collapse-foldout::after {
      transform rotate(90deg)
    }

    .collapse-content {
      opacity 1
      max-height fit-content
    }
  }
}

</style>
