<template>
  <div v-if="href && clickable" class="card card-link card-link-clickable" :class="{'card-wide': wide}" @click="open">
    <div class="card-content"><slot></slot></div>
    <div class="card-footer" v-if="footer">
      {{ footer }}
    </div>
    <div v-if="href.startsWith('https')" class="card-link-external">
      <a :href="href" target="_blank" rel="noopener noreferrer">
        <open-in-new class="icon-inline" />
      </a>
    </div>
    <div v-else class="card-link-internal">
      <router-link class="card-link-internal" tag="a" :to="href">
        <expand class="icon-inline" />
      </router-link>
    </div>
  </div>
  <div v-else-if="href" class="card card-link" :class="{'card-wide': wide}">
    <div class="card-content"><slot></slot></div>
    <div class="card-footer" v-if="footer">
      {{ footer }}
    </div>
    <div v-if="href.startsWith('https')" class="card-link-external">
      <a :href="href" target="_blank" rel="noopener noreferrer">
        <open-in-new class="icon-inline" />
      </a>
    </div>
    <div v-else class="card-link-internal">
      <router-link class="card-link-internal" tag="a" :to="href">
        <expand class="icon-inline" />
      </router-link>
    </div>
  </div>
  <div v-else class="card" :class="{'card-wide': wide}">
    <div class="card-content"><slot></slot></div>
    <div class="card-footer" v-if="footer">
      {{ footer }}
    </div>
  </div>
</template>

<script>
import OpenInNew from '../icons/OpenInNew.vue';
import Expand from '../icons/Expand.vue';

export default {
  name: "Card",
  props: ["href", "clickable", "footer", "wide"],
  components: {
    OpenInNew,
    Expand,
  },
  methods: {
    open(event) {
      if (this.href.startsWith('https')) {
        this.openExternal(this.href)
      } else {
        this.openInternal(this.href)
      }
    },
    openExternal(href) {
      window.open(href, '_newtab', 'noopener, noreferrer')
    },
    openInternal(href) {
      this.$router.push({path: href})
    },
  },
};
</script>

<style lang="stylus" scoped>
.card.card-dark:not(:hover) {
  .card-content {
    p, h1, h2, h3, h4, h5, h6 {
      color #fff
    }
  }
}
</style>

<style lang="stylus">
.card {
  h1, h2, h3, h4, h5, h6, p, pre, blockquote, ul, ol {
    &:first-child {
      margin-top 0
    }
    &:last-child {
      margin-bottom 0
    }
  }

  .richtext {
    transition color 0.1s ease-in-out
    color $secondaryAccent

    .admonition {
      transition 0.1s ease-in-out
      filter saturate(70%)
    }
  }

  .card-content {
    position relative
  }

  display block
  width calc(50% - 10px)
  border-radius 20px
  position relative
  padding 18px
  transition background-color 0.2s ease-in-out
  -moz-transition: background-color 0.2s ease-in-out;
  transition color 0.1s ease-in-out
  box-shadow:0px 10px 20px #00000010
  outline 0px solid white
  transition margin 0.2s ease-in-out, box-shadow 0.1s ease-in-out

  .card-link-external, .card-link-internal {
    display none
  }

  &::before {
    position absolute
    content " "
    transition 0.1s
    top 0
    left 0
    bottom 0
    right 0
    border 0px solid #fff
    border-radius inherit
  }

  &:hover {
    .richtext {
      color black
      
      .admonition {
        transition 0.1s ease-in-out
        filter saturate(100%)
      }
    }

    box-shadow 0px 10px 22px rgba(0,0,0,0.12)
    margin-top -2px
    margin-bottom 2px

    &::before {
      top -2px
      left -2px
      bottom -2px
      right -2px
      border 3px solid #fff
      border-radius 24px
    }

    .card-link-external, .card-link-internal {
      display block
    }
  }

  .card-link-external, .card-link-internal {
    font-size 1.5rem
    position absolute
    top 0px
    right 0px
  
    a {
      padding 8px
      width calc(1.5rem + 16px)
      height calc(1.5rem + 16px)
      display block
    }

    &.card-link-clickable {
      *:not([fill="none"]) {
        fill $active
      }
    }
  }

  &-dark {
    border 1px solid #def8
    background-color #01020444

    &:hover {
      background-color #fff
      border-color #fff
      color $secondaryAccent

      li::marker {
        color $secondaryMedium
      }

      * {
        text-shadow unset
      }
    }
  }

  &-link-clickable {
    cursor pointer
  }

  +below(700px) {
    width 100%
    height fit-content
  }

  &-wide {
    width 100%
    height fit-content
  }

  .card-p {
    margin-top 0.5em
  }
  
  br {
    margin-top 0
  }

  strong {
    margin-bottom 6px
  }
  
  &_links {
    display flex
    flex-direction column
  }

  display flex
  flex-direction column

  .card-content {
    flex-grow 1
  }

  .card-footer {
    flex-grow 0
    font-size 13px
    font-weight 200
    margin-top 7px
    margin-bottom -7px
  }
}
</style>