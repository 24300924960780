const unidecode = require('unidecode')

const slugify = (str) => {
  str = str.trim()
  str = unidecode(str)
  str = str.toLowerCase()
  str = str.replace('c#', 'csharp')
  str = str.replace('c++', 'cpp')
  str = str.replace(/[^a-z0-9 _-]/g, '')
  str = str.replace(/[ _]+/g, '-')
  str = str.replace(/-+/g, '-')
  return str;
}

export default slugify