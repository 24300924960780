<template>
  <div class="docs">
    <main-header class="main-header-light" :breadcrumbs="['Docs']"/>
    <div class="docs_success">
      <div class="page with-sidebar with-outline">
        <h1 class="page-title">📚 Docs</h1>
        <div class="page-sidebar docs">
          <div class="page-sidebar-content">
            <div class="section-title">Documentation</div>
            <tree :nodes="getSidebarTree()" v-if="tree" :prefix="`/docs`"/>
          </div>
        </div>
        <div class="page-content" ref="page_content">
          <h1>Documentation</h1>
          <template v-if="categoriesLoaded">
            <template v-if="(sections && sections.length > 0) || (categories && categories.length > 0)">
              <p>Documentation for Haply products is split into directories, grouped by product area, with each directory covering a particular hardware or software component.</p>
              <p class="info">
                <icon-inline><info-icon/></icon-inline> Each directory is organized according to the <a href="https://diataxis.fr/" target="_blank" rel="noopener noreferrer">Diátaxis</a> framework.
              </p>
              <template v-for="category in categories" v-if="categoriesLoaded">
                <h2>{{ category.name }}</h2>
                <p v-if="category.description">{{ category.description }}</p>
                <card-layout>
                  <card-row>
                    <template v-for="section in category.sections">
                      <card v-if="section.latest_version && section.latest_version.first" :key="section.name" :href="`/docs/${section.slug}/${section.latest_version.version_slug}`" :clickable="true" style="background: white;" :wide="section.emphasize">
                        <h3>📘 {{ section.name }} <span class="section-version">{{ section.latest_version.version }}</span></h3>
                        <p v-if="section.description">{{ section.description }}</p>
                        <p v-else="section.description">{{ section.name }} {{ section.latest_version.version }} documentation.</p>
                        <ul>
                          <!-- <li><router-link :to="`/docs/${section.slug}/${section.latest_version.version_slug}`" @click.stop>Index</router-link></li> -->
                          <li @click.stop><router-link :to="section.latest_version.first.path">{{section.latest_version.first.name}}</router-link></li>
                        </ul>
                        <p v-if="section.versions.length > 1">Browse past versions <router-link to="">here</router-link>.</p>
                      </card>
                    </template>
                  </card-row>
                </card-layout>
              </template>
              <template v-if="sections && sections.length > 0">
                <h2>More</h2>
                <card-layout>
                  <card-row>
                    <template v-for="section in sections">
                      <card v-if="section.latest_version && section.latest_version.first" :key="section.name" :href="`${section.latest_version.first.path}`" :clickable="true" style="background: white;">
                        <h3>📘 {{section.name}}</h3>
                        <p v-if="section.description">{{ section.description }}</p>
                        <ul>
                          <li v-for="node in section.tree">
                            {{ node.name }}
                          </li>
                        </ul>
                      </card>
                    </template>
                  </card-row>
                </card-layout>
              </template>
            </template>
            <template v-else>
              <p class="info">
                <icon-inline><info-icon/></icon-inline> Our documentation is currently under review &mdash; check back soon!
              </p>
            </template>
          </template>
          <loader v-else/>
          <h2>FAQ</h2>
          <p>For answers to frequently-asked questions, head over to our support site at <a href="https://helpdesk.haply.co/faqs" target="_blank" rel="noopener noreferrer">helpdesk.haply.co</a>.</p>
        </div>
        <div class="page-outline">
          <div class="page-outline-content">
            <h2>On this page 📃</h2>
            <outline v-if="parts" :parts="parts"/>
            <div v-else>Loading</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReleaseTag from "../components/releases/ReleaseTag"
import Timestamp from "../components/UI/Timestamp"
import MainHeader from "../components/UI/MainHeader"
import Loader from "../components/UI/Loader"
import Card from "../components/UI/Card.vue"
import CardRow from "../components/UI/CardRow.vue"
import CardLayout from "../components/UI/CardLayout.vue"
import Tree from "../components/docs/Tree.vue"
import Outline from "../components/docs/Outline.vue"
import InfoIcon from "../components/icons/InfoIcon.vue"
import IconInline from '../components/UI/IconInline.vue'
import OutlineIcon from '../components/icons/OutlineIcon.vue'
import UnfoldLessIcon from '../components/icons/UnfoldLessIcon.vue'
import UnfoldMoreIcon from '../components/icons/UnfoldMoreIcon.vue'
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon.vue'
import ChevronRightIcon from '../components/icons/ChevronRightIcon.vue'
import * as API from "../utils/js/api"
import * as Entries from "../utils/js/entries"
import * as Docs from "../utils/js/docs"
import slugify from "../utils/js/slugify"
import sleep from "../utils/js/sleep"

export default {
  components: {
    MainHeader,
    Loader,
    ReleaseTag,
    Timestamp,
    Card,
    CardRow,
    CardLayout,
    Tree,
    Outline,
    InfoIcon,
    IconInline,
    OutlineIcon,
    UnfoldLessIcon,
    UnfoldMoreIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      sections: [],
      sectionsLoaded: false,
      categories: [],
      categoriesLoaded: false,
      tree: null,
      parts: null,
    };
  },
  mounted() {},
  methods: {
    getSidebarTree() {
      return [
        {
          'name': 'Overview',
          'slug': '',
          'path': `/docs`,
          'type': 'document',
        },
        ...this.tree
      ]
    },
    async getSections() {
      const params = {
        'populate[versions]': 1,
      }
      const response = await API.get(`/docs-sections`, params)
      const sections = Entries.restructureListResponse(response)
      // We only want to display sections with a published docs tree
      // version, and where the former has one or more documents
      const visibleSections = Docs.getVisibleSections(sections)
      const {sectionsWithNoCategory, categories} = await Docs.byCategory(visibleSections)
      const tree = Docs.flattenCategoryTree(categories, sectionsWithNoCategory)

      this.sections = sectionsWithNoCategory
      this.sectionsLoaded = true
      this.categories = categories
      this.categoriesLoaded = true
      this.tree = tree

      await sleep(1000)
      await this.getParts()
    },
    async getParts() {
      const content = this.$refs.page_content
      let headings = content.querySelectorAll('h1, h2');
      let parts = []
      for (const heading of headings) {
        heading.id = slugify(heading.textContent)
        parts.push({
          text: heading.textContent,
          level: heading.tagName.substring(1),
          anchor: heading.id,
        });
      }

      parts[0].text = 'Introduction'

      this.parts = parts
    }
  },
  created() {
    this.getSections()
  },
};
</script>

<style lang="stylus" scoped>

.page-sidebar-content {
  .section-title {
    font-size 1.15em
    margin-bottom 0.5em
  }

  .version-select {
    font-size 0.9rem
    font-weight 200

    .version-select-dropdown {
      font-size 0.9rem
      font-weight 200
    
      background none
      border none

      option {
        font-size inherit
        font-weight inherit
        font-family "myriad-pro", sans-serif
      }
    }
  }
}

.page-content {
  .section-version {
    font-weight 200
  }

  .card {
    ul {
      padding-left 30px
      li::marker {
        content: '📄 '
      }
    }
  }
}
</style>