<template>
  <span class="inline-icon-wrapper">
    &#9608;
    <slot class="inline-icon"></slot>
  </span>
</template>

<script>
export default {
  name: "InlineIcon",
};
</script>

<style lang="stylus">
.inline-icon-wrapper {
  display inline-block
  position relative
  color #0000
  min-width 1.4em
  margin-left -0.2em
  margin-right -0.2em
}

.inline-icon-wrapper {
  .icon {
    position absolute
    max-width 100%
    max-height 100%
    left 0
    top 0.05em
  }
}
</style>