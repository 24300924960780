
const pluralRules = new Intl.PluralRules('en-US', {
type: 'ordinal'
})

const suffixes = {
'one': 'st',
'two': 'nd',
'few': 'rd',
'other': 'th'
}

const convertToOrdinal = (number) => `${number}${suffixes[pluralRules.select(number)]}`

export const getRelativeDate = (localDate, weekday) => {
    const now = new Date(Date.now())
    const dt = (now - localDate) / (1000 * 3600 * 24)
    const day = localDate.toLocaleDateString('en-CA', {day: "numeric"})

    if (dt < 1 && localDate.getDay() == now.getDay()) {
        return "Today"
    } else if (dt < 2 && (localDate.getDay() + 1 % 7) == now.getDay()) {
        return "Yesterday"
    } else if (Math.ceil(dt) < 7) {
        if (localDate.getDay() < now.getDay()) {
            return weekday
        } else {
            return `Last ${weekday}`
        }
    }

    const fullMonthDay = `${localDate.toLocaleDateString('en-CA', { month: "long", year: undefined })} ${convertToOrdinal(day)}`
    if (localDate.getFullYear() == now.getFullYear()) {
        return fullMonthDay
    } else {
        return `${fullMonthDay}, ${localDate.toLocaleDateString('en-CA', { month: undefined, year: "numeric" })}`
    }
}

export const splitDateTime = (str) => {
  const localDate = new Date(str)
  const weekday = localDate.toLocaleString('en-CA', {weekday: 'long'})
  const relativeDate = getRelativeDate(localDate, weekday)
  return {
    date: `${localDate.toLocaleDateString('en-CA', {year: "numeric"})}-${localDate.toLocaleDateString('en-CA', {month: "2-digit"})}-${localDate.toLocaleDateString('en-CA', {day: "2-digit"})}`,
    relativeDate: relativeDate,
    weekday: weekday,
    month: localDate.toLocaleDateString('en-CA', {month: "long"}),
    time: localDate.toLocaleTimeString('en-CA', {hour: "numeric", minute: "2-digit"}),
  }
}

export const restructureRelationAttributes = (attributes) => {
    for (const key in attributes) {
      if (attributes.hasOwnProperty(key)) {
        const value = attributes[key]
        if (typeof value == 'object' && value != null && 'data' in value) {
          const data = value.data
          if (data == null) {
            attributes[key] = null
          } else {
            if ('id' in data) {
              attributes[key] = restructureEntry(data)
            } else if ( Array.isArray(data) ) {
              attributes[key] = restructureList(data)
            }
          }
        }
      }
    }
    return attributes
}

export const restructureEntry = (entry) => {
    const { date: createdAtDate, relativeDate: createdAtRelativeDate, month: createdAtMonth, weekday: createdAtWeekday, time: createdAtTime} = splitDateTime(entry.attributes.createdAt)
    const { date: updatedAtDate, relativeDate: updatedAtRelativeDate, month: updatedAtMonth, weekday: updatedAtWeekday, time: updatedAtTime} = splitDateTime(entry.attributes.updatedAt)
    const { date: publishedAtDate, relativeDate: publishedAtRelativeDate, month: publishedAtMonth, weekday: publishedAtWeekday, time: publishedAtTime} = splitDateTime(entry.attributes.publishedAt)
    return {
        id: entry.id,
        ... restructureRelationAttributes(entry.attributes),
        createdAtDate: createdAtDate,
        createdAtRelativeDate: createdAtRelativeDate,
        createdAtMonth: createdAtMonth,
        createdAtWeekday: createdAtWeekday,
        createdAtTime: createdAtTime,
        updatedAtDate: updatedAtDate,
        updatedAtRelativeDate: updatedAtRelativeDate,
        updatedAtMonth: updatedAtMonth,
        updatedAtWeekday: updatedAtWeekday,
        updatedAtTime: updatedAtTime,
        publishedAtDate: publishedAtDate,
        publishedAtRelativeDate: publishedAtRelativeDate,
        publishedAtMonth: publishedAtMonth,
        publishedAtWeekday: publishedAtWeekday,
        publishedAtTime: publishedAtTime,
    }
}

export const restructureList = (list) => {
    return list.map((entry) => restructureEntry(entry))
}

export const restructureListResponse = (response) => {
    return response.data.data.map((entry) => restructureEntry(entry))
}

export const restructureEntryResponse = (response) => {
    return restructureEntry(response.data.data)
}

export const resolveProductPaths = (products) => {
    const productPaths = {}
    for (const index in products) {
        const product = products[index]
        var path = product.slug
        // if (product.product_subgroup) {
        //   path = `${product.product_subgroup.slug}${path}`
        // }
        if (product.product_group) {
        path = `${product.product_group.slug}${path}`
        }
        productPaths[path] = product.id
        product['resolved_path'] = path 
    }
    return productPaths
}

export const resolveDocumentPaths = (articles) => {
    const articlePaths = {}
    for (const index in articles) {
        const product = articles[index]
        var path = product.slug
        if (product.product_subgroup) {
        path = `${product.product_subgroup.slug}${path}`
        }
        if (product.product_group) {
        path = `${product.product_group.slug}${path}`
        }
        articlePaths[path] = product.id
        product['resolved_path'] = path 
    }
    return articlePaths
}

const flatten_groups = (entriesByGroup, entries_key, top_groups, sub_groups) => {
  const flattened_groups = []
  const group_ids = [...Object.keys(entriesByGroup)]
  for (var i = 0; i < group_ids.length; i++) {
    const group_id = group_ids[i]
    const group = entriesByGroup[group_id]
    const flattened_group = {
      ...top_groups.find(({id}) => id == group_id),
      ...group,
    }
    if (flattened_group.subgroups) {
      const subgroup_ids = Object.keys(flattened_group.subgroups)
      const flattened_subgroups = []
      for (var j = 0; j < subgroup_ids.length; j++) {
        const subgroup_id = subgroup_ids[j]
        const subgroup = flattened_group.subgroups[subgroup_id]
        const flattened_subgroup = {
          ...sub_groups.find(({id}) => id == subgroup_id),
          ...subgroup,
        }
        flattened_subgroup[entries_key] = flattened_subgroup.entries
        flattened_subgroups.push(flattened_subgroup)
      }
      flattened_group.subgroups = flattened_subgroups
      flattened_group[entries_key] = flattened_group.entries
    }
    flattened_groups.push(flattened_group)
  }
  return flattened_groups
}

export const organizeBy = (entries, top_groups, sub_groups) => {
  const entries_key = entries.key
  entries = entries.entries
  const top_group_key = top_groups.key
  top_groups = top_groups.entries
  const sub_group_key = sub_groups.key
  sub_groups = sub_groups.entries

  const entriesNoGroup = []
  const entriesByGroup = {}
  for (var i = 0; i < entries.length; i++) {
    const entry = entries[i]
    if (entry[top_group_key]) {
      if (entry[sub_group_key]) {
        if (entry[top_group_key].id in entriesByGroup) {
          if('subgroups' in entriesByGroup[entry[top_group_key].id]) {
            if (entry[sub_group_key].id in entriesByGroup[entry[top_group_key].id].subgroups) {
              entriesByGroup[entry[top_group_key].id].subgroups[entry[sub_group_key].id].entries.push(entry)
            } else {
              entriesByGroup[entry[top_group_key].id].subgroups[entry[sub_group_key].id] = { entries: [entry] }
            }
          } else {
            entriesByGroup[entry[top_group_key].id].subgroups = {}
            entriesByGroup[entry[top_group_key].id].subgroups[entry[sub_group_key].id] = { entries: [entry] }
          }
        } else {
          entriesByGroup[entry[top_group_key].id] = {
            subgroups: {}
          }
          entriesByGroup[entry[top_group_key].id].subgroups = {}
          entriesByGroup[entry[top_group_key].id].subgroups[entry[sub_group_key].id] = { entries: [entry] }
        }
      } else {
        if (entry[top_group_key].id in entriesByGroup) {
          if ('entries' in entriesByGroup[entry[top_group_key].id]) {
            entriesByGroup[entry[top_group_key].id].entries.push(entry)
          } else {
            entriesByGroup[entry[top_group_key].id] = {
              ...entriesByGroup[entry[top_group_key].id],
              entries: [entry]
            }
          }
        } else {
          entriesByGroup[entry[top_group_key].id] = { entries: [entry] }
        }
      }
    } else {
      entriesNoGroup.push(entry)
    }
  } 

  const groups = flatten_groups(entriesByGroup, entries_key, top_groups, sub_groups)
  
  const data = {}

  data[entries_key] = entriesNoGroup
  data['groups'] = groups

  return data
}