import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../views/Welcome.vue'
import Documentation from '../views/Documentation.vue'
import Section from '../views/Section.vue'
import SectionVersion from '../views/SectionVersion.vue'
import Document from '../views/Document.vue'
import ProductReleases from '../views/ProductReleases.vue'
import Group from '../views/Group.vue'
import Releases from '../views/Releases.vue'
import LatestReleases from '../views/LatestReleases.vue'
import ReleaseInfo from '../views/ReleaseInfo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/docs',
    name: 'Docs',
    component: Documentation,
  },
  {
    path: '/docs/:section',
    name: 'Section',
    component: Section,
  },
  {
    path: '/docs/:section/:version',
    name: 'SectionVersion',
    component: SectionVersion,
  },
  {
    path: '/docs/:section/:version/:document+',
    name: 'Document',
    component: Document,
  },
  {
    path: '/releases',
    name: 'Releases',
    component: Releases,
  },
  {
    path: '/releases/latest',
    name: 'Latest Releases',
    component: LatestReleases,
  },
  {
    path: '/releases/:product',
    name: 'Product Releases',
    component: ProductReleases,
  },
  {
    path: '/releases/:product/:version',
    name: 'Release Details',
    component: ReleaseInfo,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.path != from.path) {
        window.scroll(0, 0)
      }
    }
  }
})

export default router
