<template>
  <header class="main-header">
    <div class="main-header-left">
      <span class="main-header-left-links">
        <router-link to="/"><img src="/assets/images/haply-icon-white.svg" class="main-header-logo" alt /></router-link>
        <span>
          <router-link to="/"><span class="main-header_title"><strong>Haply</strong> Developer Hub</span></router-link>
        </span>
      </span>
    </div>
    <menu-icon class="open-menu-icon" ref="openMenu"></menu-icon>
    <div class="main-header-menu" :class="{expanded: menuExpanded}">
      <div class="main-header-nav">
        <ul class="main-header-nav-links">
          <li><router-link to="/"><span>Home</span></router-link></li>
          <li><router-link to="/releases"><span>Releases</span></router-link></li>
          <li><a href="https://docs.haply.co/" rel="noopener noreferrer"><span>Docs</span></a></li>
        </ul>
      </div>
      <div class="main-header-ext">
        <ul class="main-header-ext-links">
          <li>
            <a href="https://haply.co/" target="_blank" rel="noopener noreferrer" class="main-header-nav_link-external">
              Main site&nbsp;<open-in-new class="icon-inline"/>
            </a>
          </li>
        </ul>
      </div>
      <close-icon class="close-menu-icon" ref="closeMenu"></close-icon>
    </div>
  </header>
</template>

<script>
import SearchEngine from "../UI/SearchEngine";
import OpenInNew from "../icons/OpenInNew";
import MenuIcon from "../icons/MenuIcon"
import CloseIcon from "../icons/CloseIcon"

export default {
  name: "MainHeader",
  components: {
    SearchEngine,
    OpenInNew,
    MenuIcon,
    CloseIcon,
  },
  data() {
    return {
      menuExpanding: false,
      menuExpanded: false,
      didBind: false,
    };
  },
  methods: {
    openMenu() {
      this.menuExpanded = true
      this.menuExpanding = true
      window.setTimeout(() => {
        this.menuExpanding = false
      },200)
    },
    closeMenu() {
      this.menuExpanded = false;
    },
    closeIfNotOpening() {
      if (!this.menuExpanding) {
        this.closeMenu();
      }
    },
  },
  mounted () {
    this.$refs["openMenu"].$el.addEventListener("click", this.openMenu)
    this.$refs["closeMenu"].$el.addEventListener("click", this.closeMenu)
    let stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        document.querySelector(".page").addEventListener("mousemove", this.closeIfNotOpening)
        document.querySelector("footer").addEventListener("mousemove", this.closeIfNotOpening)
        this.didBind = true
      }
    }, 100);
  },
  beforeUnmount() {
    if (this.didBind) {
      document.querySelector(".page").removeEventListener("mousemove", this.closeIfNotOpening)
      document.querySelector("footer").removeEventListener("mousemove", this.closeIfNotOpening)
    }
  },
};
</script>
<style lang="stylus" scoped>
.icon-inline {
  width 0.8em
  height 0.8em
  max-width 0.8em
  max-height 0.8em
}

.h-100 {
  height 100%
}

@css {
  .main-header-light .main-header-logo {
    filter: brightness(0%);
  }
}

.main-header {
  width 100vw
  max-width 100%
  height $headerHeight
  position absolute
  top 0
  left 0
  z-index 3
  display flex
  justify-content space-between
  align-items center
  padding 10px $horPadding
  color white

  &-menu {
    position absolute
    left calc(100% / 3)
    right 0
    top 0
    height $headerHeight
  }

  svg.icon /deep/ path:not([fill="none"]) {
    fill #fff 
  }

  svg.icon:hover /deep/ path:not([fill="none"]) {
    fill $activeOnDark
  }

  .open-menu-icon, .close-menu-icon {
    display none
    width 30px
    height 30px
  }

  &_title {
    white-space nowrap
  }

  &:not(-main-header-light) .main-header_title {
    font-weight 200
  }

  &-nav {
    &_link-external {
      white-space nowrap
      font-weight 200
    }
    &-links {
      & li {
        & a {
          & span {
            white-space nowrap
          }
        }
      }
    }
  }
  
  &-light {
    position fixed
    //ackground linear-gradient(90deg, #fff, #a8f)
    background white
    color #444

    svg.icon /deep/ path:not([fill="none"]) {
      fill #444  
    }

    .main-header_title {
      color #777
    }

    .main-header-nav {
      &_link-external {
        &:hover {
          color $activeOnDark
          filter brightness(80%)
          svg.icon /deep/ path:not([fill="none"]) {
            fill $activeOnDark
            filter unset
          }
        }
      }
    }

    strong {
      color black
      font-weight 400
    }

    border-bottom 0.5px solid #ddd

    & .main-header-nav {
      &-links {
        color #555
        & * {
          font-weight 200
        }
      }
    }

    & .main-header-ext-links {
      & * {
        font-weight 200
      }
    }
  }

  a {
    // margin 0
    text-decoration none
  }

  &-left {
    display flex
    flex-direction row
    flex-wrap nowrap
    height 100%
    max-height 100%

    &-links {
      display flex
      flex-wrap nowrap
      flex-direction row
      height 100%
      max-height 100%
      align-items center
      gap 20px
  
      & > :first-child {
        display flex
        align-items center
      }

      img {
        height 38px
        max-height 38px
        width auto
      }
    }

    &_breadcrumbs {
      padding-left 2px
      & * {
        font-weight 200
        font-size 1em
        color $secondary
      }
    }

    &_breadcrumb {
      position relative
      &:before {
        content "/"
        padding-left 4px
        padding-right 4px
        color #ccc
      }

      & > a {
        font-size 1em

        &:hover {
          color $activeOnDark
          fill $activeOnDark
          filter brightness(80%)
        }
      }
    }
  }

  h1 {
    color white
    font-size 1.3rem
  }

  &-nav {
    position absolute
    left 0
    right 50%
    display flex
    flex-direction row
    flex-wrap nowrap
    align-items center
    justify-content center

    height 100%
    ul {
      padding-left 0
      display flex
      flex-direction row
      flex-wrap nowrap
      align-items center
      justify-content center
      height 100%
      margin 0

      li {
        display block
        height 100%
        
        a {
          padding-left 30px
          padding-right 30px
          padding-top 2px
          height 100%
          display flex
          align-items center
          border-bottom 2px solid #fff0
          +below(1200px) {
            border-left 2px solid #fff0
            border-bottom none
          }
          &:hover {
            color $activeOnDark
            border-color $activeOnDark
          }

          &.router-link-active:not([href='/']), &.router-link-exact-active {
            & > span {
              position relative

              &::after {
                content: " ";
                position: absolute;
                right: -10px;
                top: calc(50% - 5px);
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background: $activeOnDark;
              }
            }
          }
        }
      }
    }

    ul:not(:hover) li a.router-link-exact-active {
      color $activeOnDark
      border-bottom 2px solid $activeOnDark

      +below(1200px) {
        border-left 2px solid $activeOnDark
        border-bottom none
      }
    }
  }

  &-ext {
    position absolute
    left calc(2 * 100% / 3)
    right $horPadding
    height 100%
    display flex
    flex-direction row
    flex-wrap nowrap
    align-items center
    justify-content flex-end
    
    li {
      a:hover {
        color $activeOnDark

        svg.icon /deep/ path:not([fill="none"]) {
          fill $activeOnDark
        }
      }

      a.main-header-nav_link-external {
        padding-left 20px
        padding-right 20px
      }

      a.doc {
        position relative

        &:before {
          content 'alpha'
          position absolute
          top -40%
          right -20%
          text-transform uppercase
          font-size 0.55rem
        }
      }
    }

    li:last-child {
      a.main-header-nav_link-external {
        padding-right 0
      }
    }

    +below(770px) {
      display none
    }

    height calc(100% + 21px)
    margin -10px 0
    ul {
      padding-left 0
      display flex
      flex-direction row
      flex-wrap nowrap
      align-items center
      justify-content center
      height 100%
      margin 0

      li {
        display block
        height 100%
        
        a {
          height 100%
          display flex
          align-items center
        }
      }
    }
  }

  +below(1200px) {
    .open-menu-icon, .close-menu-icon {
      position absolute
      right 20px
      top 15px
    }
    .open-menu-icon {
      display block
    }

    &-menu {
      position fixed
      height 100vh
      max-height 100vh
      left unset
      background #02010690
      backdrop-filter blur(10px)
      display flex
      align-items unset
      flex-direction column
      justify-content flex-start
      padding unset
      margin-right -310px
      min-width 250px
      max-width 100%

      &.expanded {
        margin-right 0px
        box-shadow 0px 0px 60px #ffffff08
        border-left 0.5px solid #0006

        .open-menu-icon {
          display none
        }
        .close-menu-icon {
          display block
        }
      }
    }

    &-nav {
      position relative
      display block
      width 100%
      height fit-content
      left unset
      right unset
      margin unset
      padding unset
      
      ul {
        display block
        height fit-content
        padding 20px 0

        li {
          width 100%
          text-align left

          a {
            display block
            padding 10px $horPadding !important
          }
        }
      }
    }

    &-ext {
      position relative
      display block
      width 100%
      height fit-content
      left unset
      right unset
      margin unset
      padding unset
      border-top 0.5px solid #fff4

      ul {
        display block
        height fit-content
        padding 20px 0

        li {
          width 100%
          text-align left

          a {
            display block
            padding 10px $horPadding !important
          }
        }
      }
    }
  }
}

.main-header-light {
  +below(1200px) {
    .main-header-menu {
      background white
      border-left none
      box-shadow 0px 0px 10px #0001
    }

    .main-header-ext {
      border-color #ddd
    }
  }

  .main-header-nav_link-external:hover {
    color $active
  
    svg.icon /deep/ path:not([fill="none"]) {
      fill $active
    }
  }

  svg.icon:hover /deep/ path:not([fill="none"]) {
    fill $active
  }

  .main-header-nav {
    ul {
      li {
        a {
          padding-top 3px
          border-bottom 3px solid alpha($active, 0)
          +below(1200px) {
            border-bottom none
            border-left 2px solid alpha($active, 0)

            &.router-link-active:not([href='/']), &.router-link-exact-active {
              & > span::after  {
                background: $active;
              }
            }
          }
        
          &:hover {
            color $active
            transition color 0.2s
            border-bottom 3px solid $active
            +below(1200px) {
              border-bottom none
              border-left 2px solid $active
            }
            * {
              filter brightness(90%)
            }
          }
        }
      }
    }

    ul:not(:hover) {
      li {
        a.router-link-active:not([href="/"]) {
          color $active
          transition color 0.2s
          border-bottom 3px solid $active
          +below(1200px) {
            border-bottom none
            border-left 2px solid $active
          }
          * {
            filter brightness(90%)
          }
        }
        a.router-link-exact-active {
          color $active
          transition color 0.2s
          border-bottom 3px solid $active
          +below(1200px) {
            border-bottom none
            border-left 2px solid $active
          }
          * {
            filter brightness(90%)
          }
        }
      }
    }
  }
}

</style>
