<template>
    <span class="release-title"><span class="product-name">{{release.product.name}}</span> <release-tag :release="release" /></span>
</template>

<script>
import ReleaseTag from "./ReleaseTag.vue";

export default {
  name: "ReleaseTitle",
  components: {
    ReleaseTag,
  },
  props: ["release"],
};
</script>

<style lang="stylus">
.release {
  &-version {
    color $primary
  }
  
  &-version-tag {
    transform translate(0px, 0.09em)
    
    path:not([fill="none"]) {
      fill $primary
    }
    circle:not([fill="none"]) {
      fill $primary
    }
  }
}
</style>