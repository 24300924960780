<template>
  <blockquote class="admonition" ref="blockquote" :class="getTypeClass(type)">
    <p>
      <strong  v-if="title" class="admonition-title">
        {{ title }}
      </strong>
      <slot></slot>
    </p>
  </blockquote>
</template>

<script>
export default {
  name: "Admonition",
  props: ["title", "type"],
  methods: {
    getTypeClass(type) {
      if (type) {
        let c = {}
        c[`admonition-type--${type}`] = true
        return c
      }
    }
  }
};
</script>