import slugify from "./slugify"

const quadrants = [
  { type: 'how-to-guides', keys: ['guide'] },
  { type: 'tutorials', keys: ['tutorial'] },
  { type: 'explanation', keys: ['explanation', 'deep-dive'] },
  { type: 'reference', keys: ['ref'] },
]

const getQuadrant = (key) => {
  const slug = slugify(key)
  const types = quadrants.filter(x => x.keys.filter(k => slug.includes(k)).length > 0)
  if (types.length > 0) {
    return types[0].type
  } else{
    return null
  }
}

export {
  quadrants,
  getQuadrant,
}