<template>
  <ul class="outline" ref="outline">
    <li :class="`outline_element_h${part.level}`"
      @mouseenter="onMouseIn(i)"
      @mouseleave="onMouseOut(i)"
      v-for="(part, i) in parts"
      :ref="`part${i}`"
      :key="part.anchor"
      :data-anchor="part.anchor"
      @click="onPartClick(part)"
      :style="getPartStyle(part)"
    >{{part.text}}</li>
  </ul>
</template>

<script>
import throttle from 'lodash/throttle'

export default {
  name: "Outliner",
  props: ["parts", "context"],
  data() {
    return {
      hoverPart: null,
      isSmoothScrolling: false,
    }
  },
  mounted() {
    this.handleScrollThrottled = throttle(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleScrollThrottled)
    for (const part of this.parts) {
      const element = document.getElementById(part.anchor)
      element.onclick = () => {
        this.onPartClick(part)
        navigator.clipboard.writeText(window.location.href)
      }
    }
    this.handleScroll(null)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrollThrottled)
    window.removeEventListener('scroll', this.handleScroll)
  },  
  methods: {
    getPartStyle(part) {
      return `padding-left: ${(Math.max(part.level, 2) - 2) * 8}px`
    },
    handleScroll(event) {
      if (this.isSmoothScrolling) {
        return
      }
      let active = undefined
      if (this.parts && this.parts.length > 0) {
        active = this.parts[0]
        for(const part of this.parts) {
          const element = document.getElementById(part.anchor)
          const distance = element.getBoundingClientRect().top
          if (distance <= 150) {
            active = part
          } else {
            break
          }
        }
      }
      this.setActive(active)
    },
    setActive(part) {
      const outline = this.$refs.outline
      if (outline) {
        const currentActiveHeading = outline.querySelector('li.active')
        if (currentActiveHeading) {
          currentActiveHeading.classList.remove('active')
        }
        if (part) {
          const activeHeading = outline.querySelector(`[data-anchor="${part.anchor}"]`)
          activeHeading.classList.add('active')
        }
      }
    },
    onMouseIn(index) {
      const elements = this.getPartElements(index)
      elements.forEach(el => el.classList.add('outline-hover'))
      this.hoverPart = index
    },
    onMouseOut(index) {
      const elements = this.getPartElements(index)
      elements.forEach(el => el.classList.remove('outline-hover'))
      this.hoverPart = null
    },
    getPartElement(index) {
      return document.getElementById(this.parts[index].anchor)
    },
    getPartElements(index) {
      const element = this.getPartElement(index)
      const elements = [element]
      const last = index == this.parts.length - 1 ? null : this.getPartElement(index + 1)
      const scope = element.parentElement
      var i = Array.prototype.indexOf.call(scope.children, (element)) + 1
      for (;i < scope.children.length;i++) {
        const child = scope.children[i]
        if (child === last) {
          break
        } else {
          elements.push(child)
        }
      }
      return elements
    },
    onPartClick(part) {
      this.$router.push({hash: part.anchor})
      const el = document.getElementById(part.anchor)
      el.scrollIntoView({ behavior: "smooth" })
      this.setActive(part)
      this.isSmoothScrolling = true
      window.setTimeout(() => {
        this.isSmoothScrolling = false
      }, 101)
    }
  }
};
</script>

<style lang="stylus" scoped>
ul.outline {
  list-style none
  padding-left unset
  margin-left unset
  line-height 1

  li {
    padding 8px 0px 8px 0px
    cursor pointer
    position relative
    color #444
    transition all 0.2s
    font-weight 200
  }

  &:not(:hover) {
    li.active {
      color $active

      &::before {
        content ""
        position absolute
        display block
        left - $menuPaddingX
        width 2px
        background $active
        top 3px
        bottom 3px
      }
    }
  }

  li:hover {
    color $active

    &::before {
      content ""
      position absolute
      display block
      left - $menuPaddingX
      width 2px
      background $active
      top 3px
      bottom 3px
    }
  }
}
</style>

<style lang="stylus">
.outline-hover, .outline-heading:hover {
  color $active
  transition color 0.2s
  position relative

  &.info {
    color $active

    svg.icon {
      fill alpha($active, 80%)
    }
  }
}

pre.grvsc-container.outline-hover  {
  background $active
  transition background 0.2s

  code.grvsc-code span {
    color #fffe
    transition color 0.2s
  }
}

.img-wrapper.outline-hover  {
  position relative
  background $active
  box-shadow none

  img {
    opacity 0.8
  }

  &::before {
    content " "
    position absolute
    left -2px
    right -2px
    top -2px
    bottom -2px
    border 2px solid alpha($active, 0.75)
    border-radius 6px
  }
}

table.outline-hover {
  th, td {
    border-color $active !important
    background alpha($active, 5%) !important
    transition border-color 0.2s background 0.2s
  }
}

h1, h2, h3, h4, h5 {
  &.outline-hover, .outline-heading:hover {
    &::before {
      content ""
      position absolute
      display block
      left -22px
      width 3px
      background $active
      top 3px
      bottom 3px
    }
  }
}
</style>