<template>
  <div class="docs">
    <main-header class="main-header-light" :breadcrumbs="['Docs']"/>
    <div class="docs_success">
      <div class="page with-sidebar with-outline">
        <h1 class="page-title">
          📚 <router-link to="/docs">Docs</router-link>
          <small v-if="tree">
            <span v-if="section"> / <router-link :to="`/docs/${section.slug}`">{{section.name}}</router-link>
              <span v-if="tree"> / <router-link :to="`/docs/${section.slug}/${tree.version_slug}`">{{tree.version}}</router-link></span>
            </span>
            <template v-if="breadcrumbsLoaded"><template v-for="breadcrumb in breadcrumbs"> / <router-link :to="breadcrumb.path">{{ breadcrumb.name }}</router-link></template></template>
            <template v-if="documentNameLoaded"> / {{ documentName }}</template>
          </small>
        </h1>
        <div class="page-sidebar docs">
          <div class="page-sidebar-content">
            <div v-if="section" class="section-title">{{section.name}}</div>
            <div class="version-select">
              Version
              <select v-if="section && tree" class="version-select-dropdown">
                <template v-for="version in section.versions">
                  <option v-if="version.version === tree.version" selected>
                    📘 {{ version.version }}
                  </option>
                  <option v-else>📘 {{ version.version }}</option>
                </template>
              </select>
            </div>
            <tree :nodes="getSidebarTree()" v-if="tree && section" :prefix="`/docs/${tree.section.slug}/${tree.version_slug}`"/>
          </div>
        </div>
        <div class="page-content" v-if="documentHtmlLoaded">
          <div ref="document" v-html="documentHtml" @click="onClickDocument"></div>
          <footer class="page-footer">
            <div class="columns">
              <div class="column">
                <template v-if="section && section.product && section.product.latest_release">
                  <h2>More</h2>
                  <p>
                    📦 <router-link :to="`/releases${section.product.slug}`">{{section.product.name}} releases</router-link>
                  </p>
                </template>
              </div>
              <div class="column">
                <template v-if="nextNode">
                  <h2>Next</h2>
                  <p>
                    📄 <router-link :to="nextNode.path">{{ nextNode.name }}</router-link>
                  </p>
                </template>
              </div>
            </div>
          </footer>
        </div>
        <div class="page-content" v-else-if="directoryLoaded" ref="page_content">
          <h1 :id="directory.slug" :data-depth="directoryDepth">{{ directory.name }}</h1>

          <p v-if="directory.description">{{ directory.description }}</p>
          <p v-else-if="directoryDiataxisQuadrant === 'how-to-guides'">
            How-to guides are sets of instructions written to help you accomplish a specific task.
          </p>
          <p v-else-if="directoryDiataxisQuadrant === 'tutorials'">
            Tutorials are practical exercises, written to help you pick up new concepts.
          </p>
          <p v-else-if="directoryDiataxisQuadrant === 'explanation'">
            These are advanced learning materials, written to provide deeper insight into specific topics.
          </p>
          <p v-else-if="directoryDiataxisQuadrant === 'reference'">
            Reference materials are detail-oriented, presenting descriptive information as succinctly as possible.
          </p>

          <template v-if="directoryDiataxisQuadrant">
            <p class="info">
              <icon-inline><info-icon/></icon-inline> You can read more about how we organize our documentation <a href="https://diataxis.fr/" target="_blank" rel="noopener noreferrer">here</a>.
            </p>
          </template>

          <template v-if="directory.tree.filter(x => x.type === 'document').length > 0">
            <h2>Directory contents</h2>

            <ul v-for="node in directory.tree" class="document-list">
              <li v-if="node.type === 'document'"><router-link :to="node.path">{{ node.name }}</router-link></li>
            </ul>
          </template>
          
          <template v-if="directory.tree.filter(x => x.type === 'tree').length > 0">
            <h3>Subdirectories</h3>

            <ul v-for="node in directory.tree" class="subdirectory-list">
              <li v-if="node.type === 'tree'"><router-link :to="node.path">{{ node.name }}</router-link></li>
            </ul>
          </template>
        </div>
        <div class="page-content" v-else-if="notFound">
          <h1>Page not found. 🔭</h1>
        </div>
        <div class="page-content" v-else>
          <loader />
        </div>
        <div class="page-outline">
          <div class="page-outline-content" v-if="!notFound">
            <h2>On this page 📃</h2>
            <outline v-if="partsLoaded" :parts="parts"/>
            <div v-else>Loading</div>
            <div class="page-outline-extras">
              <template v-if="section && section.product && section.product.latest_release">
                <h3>More</h3>
                <p>
                  📦 <router-link :to="`/releases${section.product.slug}`">{{section.product.name}} releases</router-link>
                </p>
              </template>
              <template v-if="nextNode">
              <h3>Next</h3>
              <p>
                📄 <router-link :to="nextNode.path">{{ nextNode.name }}</router-link>
              </p>
            </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReleaseTag from "../components/releases/ReleaseTag";
import Timestamp from "../components/UI/Timestamp";
import MainHeader from "../components/UI/MainHeader";
import Loader from "../components/UI/Loader";
import Card from "../components/UI/Card.vue";
import CardRow from "../components/UI/CardRow.vue";
import CardLayout from "../components/UI/CardLayout.vue";
import Outline from "../components/docs/Outline";
import Tree from "../components/docs/Tree.vue";
import InfoIcon from "../components/icons/InfoIcon.vue";
import IconInline from '../components/UI/IconInline.vue'
import OutlineIcon from '../components/icons/OutlineIcon.vue';
import UnfoldLessIcon from '../components/icons/UnfoldLessIcon.vue';
import UnfoldMoreIcon from '../components/icons/UnfoldMoreIcon.vue';
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon.vue';
import ChevronRightIcon from '../components/icons/ChevronRightIcon.vue';
import * as Entries from "../utils/js/entries";
import * as Docs from "../utils/js/docs";
import * as Diataxis from "../utils/js/diataxis";
import * as API from "../utils/js/api";
import slugify from "../utils/js/slugify";
import sleep from "../utils/js/sleep";

export default {
  components: {
    MainHeader,
    Loader,
    ReleaseTag,
    Timestamp,
    Card,
    CardRow,
    CardLayout,
    Outline,
    Tree,
    IconInline,
    InfoIcon,
    OutlineIcon,
    UnfoldLessIcon,
    UnfoldMoreIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
},
  data() {
    return {
      section: null,
      tree: null,
      document: null,
      documentHtml: null,
      documentHtmlLoaded: false,
      parts: [],
      partsLoaded: false,
      directory: null,
      directoryDepth: null,
      directoryDiataxisQuadrant: null,
      directoryLoaded: null,
      notFound: false,
      breadcrumbs: [],
      breadcrumbsLoaded: false,
      documentName: null,
      documentNameLoaded: false,
      nextNode: null,
      codeBlocks: [],
    }
  },
  mounted() {
    const anchor = this.$router.currentRoute.hash
    if (anchor) {
      const id = anchor.substring(1)
      const interval = window.setInterval(() => {
        const el = document.getElementById(id)
        if (el) {
          el.scrollIntoView({behavior: 'smooth'})
          if (el) {
            window.clearInterval(interval)
          }
        }
      }, 100)
    }
  },
  methods: {
    getSidebarTree() {
      return [
        {
          'name': 'Contents',
          'slug': '',
          'path': `/docs/${this.section.slug}/${this.tree.version_slug}`,
          'type': 'document',
        },
        ...this.tree.tree
      ]
    },
    getBreadcrumbs() {
      let path = `/docs/${this.$route.params.section}/${this.$route.params.version}`
      let slugs = this.$route.params.document.split('/')
      const breadcrumbs = []
      let node = this.tree
      let i = 0 
      for(; i < slugs.length; i++) {
        for(const element of node.tree) {
          if (element.slug == slugs[i]) {
            node = element
            path = `${path}/${node.slug}`
            node.path = path
            breadcrumbs.push(node)
            break
          }
        }
      }
      // breadcrumbs[breadcrumbs.length - 1] = `📄 ${breadcrumbs[breadcrumbs.length - 1]}`
      this.breadcrumbs = breadcrumbs.filter(bc => bc.name !== '-')
      this.documentName = this.breadcrumbs.pop().name
      this.breadcrumbsLoaded = true
      this.documentNameLoaded = true
    },
    getNextNode () {
      let slugs = this.$route.params.document.split('/')
      let node = this.tree
      let i = 0 
      this.nextNode = null
      for(; i < slugs.length; i++) {
        for(let j = 0; j < node.tree.length; j++) {
          const child = node.tree[j]
          if (child.slug === slugs[i]) {
            if (child.type === 'document' && child.path === this.$route.path) {
              if (node.tree.length > j + 1) {
                this.nextNode = node.tree[j + 1]
                return
              }
            }
            node = child
            break
          }
        }
      }
    },
    async getSection() {
      const params = {
        'filters[slug]': this.$route.params.section,
        'populate[versions]': '1',
        'populate[product][populate]': '*',
      }

      const response = await API.get(`/docs-sections`, params)
      const section = Entries.restructureEntry(response.data.data[0])
 
      const product = section.product
      if (product && product.releases.length > 0) {
        for (const release of product.releases) {
          release.product = product
        }
        const releasesByDateCreated = [...product.releases].sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
        const latestRelease = section.product.releases.find(r => r.id == releasesByDateCreated[0].id)
        product.latest_release = latestRelease
      }

      this.section = section
    },
    async getTree() {
      // query the tree from from Strapi
      const params = {
        'filters[section][slug]': this.$route.params.section,
        'filters[version_slug]': this.$route.params.version,
        'populate[section]': '1',
      }

      const response = await API.get(`/docs-trees`, params)

      // restructure the tree response
      const tree = Entries.restructureEntry(response.data.data[0])

      Docs.restructureRootNodes(tree)

      this.tree = tree
    },
    onClickDocument(event) {
      if (event.target && event.target.id) {
        const codeBlockMatch = event.target.id.match(/grvsc-code-block:(\d+)/)
        if (codeBlockMatch) {
          const index = parseInt(codeBlockMatch[1])
          if (index < this.codeBlocks.length) {
            navigator.clipboard.writeText(this.codeBlocks[index])
          }
        }
      }
    },
    async getDocument () {
      const params = {
        'populate[tree]': '1',
        'filters[tree][section][slug]': this.$route.params.section,
        'filters[tree][version_slug]': this.$route.params.version,
        'filters[path]': `/docs/${this.$route.params.section}/${this.$route.params.version}/${this.$route.params.document}`,
      }
      const response = await API.get(`/docs-documents`, params)

      if (response.data.data.length > 0) {
        const document = Entries.restructureEntry(response.data.data[0])

        const md_params = {
          'type': 'docs-document',
          'id': document.id,
          'attribute': 'markdown',
        }
        const md_response = await API.get(`/markdown`, md_params)

        let parts = [];
        let doc = new DOMParser().parseFromString(md_response.data.html, 'text/html');

        let links = doc.querySelectorAll('a')
        links.forEach(link => {
          link.setAttribute('rel', 'noopener roreferrer')
          link.setAttribute('target', '_blank')
        })

        this.codeBlocks = []
        let code = doc.querySelectorAll('pre.grvsc-container')
        code.forEach((pre, index) => {
          let div = doc.createElement('div')
          div.classList = [
            'grvsc-copy',
          ]
          div.id = `grvsc-code-block:${index}`
          div.addEventListener('click', (e) => console.log('clicked'))
          let svg = doc.createElement('svg')
          svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
          svg.setAttribute('width', '20')
          svg.setAttribute('height', '20')
          svg.setAttribute('viewBox', '0 -960 960 960')
          let path = doc.createElement('path')
          path.setAttribute('d', 'M362.308-260.001q-27.008 0-45.657-18.65-18.65-18.65-18.65-45.658v-455.382q0-27.008 18.65-45.658 18.649-18.65 45.657-18.65h359.383q27.007 0 45.657 18.65 18.65 18.65 18.65 45.658v455.382q0 27.008-18.65 45.658-18.65 18.65-45.657 18.65H362.308Zm0-51.999h359.383q4.615 0 8.462-3.846 3.846-3.847 3.846-8.463v-455.382q0-4.616-3.846-8.463-3.847-3.846-8.462-3.846H362.308q-4.616 0-8.462 3.846-3.847 3.847-3.847 8.463v455.382q0 4.616 3.847 8.463 3.846 3.846 8.462 3.846ZM238.309-136.003q-27.007 0-45.657-18.65-18.65-18.65-18.65-45.657v-481.382q0-11.069 7.405-18.534 7.404-7.465 18.384-7.465 10.979 0 18.595 7.465 7.615 7.465 7.615 18.534v481.382q0 4.616 3.846 8.462 3.847 3.847 8.462 3.847h385.382q11.069 0 18.534 7.404 7.466 7.405 7.466 18.384 0 10.98-7.466 18.595-7.465 7.615-18.534 7.615H238.309ZM349.999-312V-792-312Z')
          path.setAttribute('fill', '#000000')
          svg.appendChild(path)
          div.appendChild(svg)
          pre.appendChild(div)
          this.codeBlocks.push(pre.querySelector('code').innerText)
        })

        const emptyTableHeaders = []
        doc.querySelectorAll('table > thead').forEach(thead => {
          const cells = thead.querySelectorAll('tr > th')
          let empty = true
          for (const th of cells) {
            if (th.innerHTML) {
              empty = false
              break;
            }
          }
          if (empty) {
            emptyTableHeaders.push(thead)
          }
        })
        emptyTableHeaders.forEach(thead => thead.parentElement.removeChild(thead))

        let images = doc.querySelectorAll('p > img')
        let done = []
        images.forEach(img => {
          if(!done.includes(img)) {
            if (img.parentElement.childNodes.length > 1) {
              let groups = []
              let group = []
              const from = img.parentElement
              img.parentElement.childNodes.forEach(sibling => {
                if (sibling.tagName === undefined) {
                  group.push(sibling)
                } else {
                  if (group.length > 0) {
                    groups.push(group)
                    group = []
                  }
                  groups.push([img])
                }
              })
              if (groups.length > 0) {
                groups.push(group)
              }
              groups.reverse().forEach(g => {
                const p = doc.createElement('p')
                from.insertAdjacentElement('afterend', p)
                g.forEach(el => {
                  p.appendChild(el)
                })
                if (g.length === 1 && g[0].tagName && g[0].tagName.toLowerCase() === 'img') {
                  p.classList.add('img-wrapper')
                  done.push(g[0])
                }
              })
              from.remove()
            } else if (!img.parentElement.classList.contains("img-wrapper")) {
              img.parentElement.classList.add("img-wrapper")
              done.push(img)
            }
          }
        })

        this.documentHtml = '<div class="richtext" id="intro">' + doc.documentElement.outerHTML + '</div>'
        this.documentHtmlLoaded = true

        let headings = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');
        for (const heading of headings) {
          parts.push({
            text: heading.textContent,
            level: heading.tagName.substring(1),
            anchor: heading.id,
          });
        }

        parts[0].text = 'Introduction'

        this.parts = parts
        this.partsLoaded = true

        this.document = document
      } else {
        this.parts = []
        this.partsLoaded = false
        this.document = undefined
        this.documentHtml = undefined
        this.documentHtmlLoaded = false
      }
    },
    async createTreePage () {
      let node = this.tree
      let slugs = this.$route.params.document.split('/')
      let depth = 0
      
      for (let i = 0; i < slugs.length; i++) {
        const slug = slugs[i]
        const match = node.tree.filter(x => x.slug === slug)
        if (match.length > 0) {
          node = match[0]
          depth = i
        } else {
          this.notFound = true
          return
        }
      }
      if (depth == 0) {
        this.directoryDiataxisQuadrant = Diataxis.getQuadrant(node.slug)
      } else {
        this.directoryDiataxisQuadrant = null
      }

      this.notFound = false
      this.directory = node
      this.directoryDepth = depth
      this.directoryLoaded = true

      await sleep(1000)
      this.getTreePageParts()
    },
    getTreePageParts () {
      const content = this.$refs.page_content
      let headings = content.querySelectorAll('h1, h2');
      let parts = []
      for (const heading of headings) {
        if (!heading.id) {
          heading.id = slugify(heading.textContent)
        }
        parts.push({
          text: heading.textContent,
          level: heading.tagName.substring(1),
          anchor: heading.id,
        });
      }

      parts[0].text = 'About'

      this.parts = parts
      this.partsLoaded = true
    },
    async resolve () {
      Promise.all([
        this.getSection(),
        this.getTree(),
        this.getDocument(),
      ]).then(result => {
        this.getBreadcrumbs()
        this.nextNode = null
        if (this.tree) {
          if (this.document) {
            this.getNextNode()
          } else {
            this.createTreePage()
          }
        }
      })
    },
  },
  created() {
    this.resolve()
  },
  watch: {
    '$route' (to, from) {
      if (to.path !== from.path) {
        this.resolve()
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
.page-sidebar-content {
  .section-title {
    font-size 1.15em
    margin-bottom 0.5em
  }

  .version-select {
    font-size 0.9rem
    font-weight 200

    .version-select-dropdown {
      font-size 0.9rem
      font-weight 200
    
      background none
      border none

      option {
        font-size inherit
        font-weight inherit
        font-family "myriad-pro", sans-serif
      }
    }
  }
}

.page-content {
  h1[data-depth="0"]#guides::after {
    content " 🚀"
  }

  h1[data-depth="0"]#tutorials::after {
    content " 🎓"
  }

  h1[data-depth="0"]#explanations::after {
    content " 👓"
  }

  h1[data-depth="0"]#ref::after {
    content " 📖"
  }

  ul.document-list {
    padding-left 30px

    li::marker {
      content: '📄 '
    }
  }

  ul.subdirectory-list {
    padding-left 30px

    li::marker {
    }
  }
}
</style>