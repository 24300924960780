<template>
  <div class="docs">
    <webgl-background />
    <div class="background-fade"></div>
    <main-header class="main-header-light" :breadcrumbs="['Releases']" />
    <div class="docs_success">
      <div class="page">
        <h1 class="page-title">📦 <router-link to="/releases">Releases</router-link> <small v-if="productLoaded">/ {{
          product.name }}</small></h1>
        <div class="page-content" v-if="productLoaded">
          <badge :title="product.name" :subtitle="badgeSubtitle" :subtitle_href="badgeSubtitleHref" :src="product.badge"
            :depth="1" :is_legacy="product.is_legacy || (product.product_group && product.product_group.is_legacy)" />
          <p v-if="product.description">{{ product.description }}</p>
          <!-- <p v-if="supported && supported.length > 0">
            <span class="pairs">{{ naturalLanguageList(supported.map(x => x.values ? `${x.key} ${x.values.join("/")}` : x.key)) }}</span>
          </p> -->
          <div v-if="product.external_url">
            <p v-if="product.external_url_description">
              {{ product.external_url_description }}
              <br />
              <a class="external-link" :href="product.external_url" target="_blank" rel="noopener noreferrer">View on {{
                product.external_url_name }} <open-in-new class="icon-inline" /></a>
            </p>
          </div>
          <div v-if="product.versions">
            <div class="collapse" v-for="series in product.versions"
              :class="{ expanded: series === product.versions[0] }">
              <h2 class="collapse-foldout" v-on:click="toggleExpanded">Version {{ series.number }}</h2>
              <div class="collapse-content">
                <version-table :series="series" />
              </div>
            </div>
            <!-- <div class="collapse" v-if="product.versions.filter(version => version.number.startsWith('0')).length > 0" :class="{expanded: product.versions.filter(version => !version.number.startsWith('0')).length === 0}">
              <h2 class="collapse-foldout" v-on:click="toggleExpanded">Pre-release versions</h2>
              <div class="collapse-content">
                <version-table v-for="series in product.versions.filter(version => version.number.startsWith('0'))" :series="series"/>
                <pre-release-warning />
              </div>
            </div> -->
          </div>
          <p v-if="product.versions && product.versions.length > 0" class="info">
            <icon-inline><info-icon /></icon-inline> Haply software version numbers adhere to <a
              href="https://semver.org/">Semantic Versioning 2.0.0</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebglBackground from "../components/home/WebglBackground"
import ReleaseTag from "../components/releases/ReleaseTag"
import VersionTable from '../components/releases/VersionTable'
import Timestamp from "../components/UI/Timestamp"
import MainHeader from "../components/UI/MainHeader"
import InfoIcon from '../components/icons/InfoIcon.vue'
import IconInline from '../components/UI/IconInline.vue'
import Admonition from '../components/UI/Admonition.vue'
import Badge from '../components/UI/Badge.vue'
import OpenInNew from '../components/icons/OpenInNew.vue'
import PreReleaseWarning from '../components/UI/admonitions/PreReleaseWarning.vue'
import * as API from "../utils/js/api"
import * as Entries from "../utils/js/entries.js"
import * as Releases from "../utils/js/releases.js"
import * as Docs from "../utils/js/docs"

export default {
  components: {
    WebglBackground,
    MainHeader,
    ReleaseTag,
    VersionTable,
    Timestamp,
    InfoIcon,
    IconInline,
    Admonition,
    Badge,
    OpenInNew,
    PreReleaseWarning,
  },
  data() {
    return {
      supported: null,
      product: null,
      productLoaded: false,
    };
  },
  computed: {
    badgeSubtitle() {
      if (this.product.external_url) {
        return 'external'
      } else if (this.product.releases.length > 0) {
        return `${this.product.releases.length} ${this.product.releases.length == 1 ? 'release' : 'releases'}`
      } else {
        return undefined
      }
    },
    badgeSubtitleHref() {
      return undefined
    },
  },
  mounted() {
    const params = {
      'filters[slug][$eq]': `/${this.$route.params.product}`,
      'populate[releases][populate][downloads][populate][configuration]': 1,
      'populate[product_group]': 1,
      'populate[product_subgroup]': 1,
      'populate[docs_section][populate][versions]': 1,
      'populate[icon]': 1,
      'populate[external_install_commands]': 1,
      'populate[integrates_products]': 1,
      'populate[integrated_by_products]': 1,
      'populate[installs_products]': 1,
      'populate[installed_by_products]': 1,
    }
    API.get(`/products`, params).then(response => {
      const product = Entries.restructureEntry(response.data.data[0])

      if (product.icon) {
        product.badge = product.icon.url
      } else if (product.devicon) {
        product.badge = `https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${product.devicon}/${product.devicon}-original.svg`
      } else {
        product.badge = undefined
      }

      if (product.releases.length > 0) {
        const semver = /^\d+\.\d+\.\d+/
        const releases_sorted = [...product.releases].sort((a, b) => Releases.semverCompare(a.version, b.version))
        for (const release of releases_sorted) {
          if (Releases.isPreRelease(release)) {
            release.is_pre = true
          }
        }
        // find the latest release that is not a pre-release
        const latest_release = releases_sorted.find(release => !Releases.isPreRelease(release))
        product.latest_release = latest_release

        this.supported = Releases.reduceConfiguration(latest_release)

        const version_keys = []
        const versions = {}
        const major_minor = /^\d+\.\d+/
        for (const release of product.releases) {
          if (product.docs_section && product.docs_section.versions.length > 0) {
            const docs_versions = [...product.docs_section.versions].filter(docs_section_version => {
              return docs_section_version.version === release.version
            })
            if (docs_versions && docs_versions.length > 0) {
              release.docs_version = docs_versions[0]
              if (release.docs_version.tree) {
                Docs.restructureRootNodes(release.docs_version)
                release.docs_version.first = Docs.getFirstDocumentDepthFirst(release.docs_version.tree)
              }
            }
          }
          release.product = product
          const version_key = major_minor.exec(release.version)[0]
          if (version_key in versions) {
            versions[version_key].all.push(release)
          } else {
            versions[version_key] = { all: [release] }
            version_keys.push(version_key)
          }
        }
        let versions_sorted = []
        for (const version_key of version_keys) {
          // let docs_version = undefined
          // if (product.docs_section && product.docs_section.versions.length > 0) {
          //   const docs_versions = [...product.docs_section.versions].filter(a => {
          //     const key = major_minor.exec(a.version)[0]
          //     return key === version_key
          //   })
          //   if (docs_versions && docs_versions.length > 0) {
          //     docs_version = docs_versions[0]
          //     if (docs_version.tree) {
          //       const root_nodes = JSON.parse(docs_version.tree)
          //       const get_first_document = (nodes) => {
          //         const node = nodes[0];
          //         if (node.type == 'document') {
          //           docs_version.first = node
          //         } else {
          //           get_first_document(node.tree)
          //         }
          //       }
          //       get_first_document(root_nodes)
          //     }
          //   }
          // }
          const releases_sorted = versions[version_key].all.sort((a, b) => Releases.semverCompare(a.version, b.version))
          // check for pre-releases

          versions_sorted.push({
            number: version_key,
            all: releases_sorted,
            latest: releases_sorted[0],
            // docs_version: docs_version,
          })
        }
        const major = /^(\d+)/
        const minor = /^\d+\.(\d+)/
        const patch = /^\d+\.\d+\.(\d+)/
        versions_sorted = versions_sorted.sort((a, b) => Releases.semverCompare(a.number, b.number))
        product.versions = versions_sorted
      }
      if (product.docs_section && product.docs_section.versions.length > 0) {
        const docs_versions_sorted = [...product.docs_section.versions].sort((a, b) => a.version < b.version ? 1 : -1)
        const latest_docs_version = docs_versions_sorted[0]
        product.docs_section.latest_version = latest_docs_version
        if (latest_docs_version.tree) {
          Docs.restructureRootNodes(latest_docs_version)
          latest_docs_version.first = Docs.getFirstDocumentDepthFirst(latest_docs_version.tree)
        }
      }
      this.product = product
      this.productLoaded = true
    })
  },
  methods: {
    toggleExpanded: function (event) {
      const collapse = event.target.parentNode
      collapse.classList.toggle('expanded')
    },
    naturalLanguageList: function (arr) {
      switch (arr.length) {
        case 0:
          return undefined
        case 1:
          return `${arr[0]}`
        case 2:
          return `${arr[0]} and ${arr[1]}`
        default:
          return `${arr.join(', ', arr.slice(0, arr.length - 1))}, and ${arr[arr.length - 1]}`
      }
    },
  },
};
</script>

<style lang="stylus">
.external-link {
  display inline-block
  margin-top 5px
  font-size 1.25rem
  font-weight 400 !important
  text-decoration none !important
}
</style>