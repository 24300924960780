<template>
  <div class="docs">
    <main-header class="main-header-light" :breadcrumbs="['Docs']"/>
    <div class="docs_success">
      <div class="page with-sidebar with-outline">
        <h1 class="page-title">📚 <router-link to="/docs">Docs</router-link><small v-if="section"> / {{section.name}}</small></h1>
        <div class="page-sidebar docs">
          <div class="page-sidebar-content">
            <div class="section-title">Documentation</div>
            <tree :nodes="getSidebarTree()" v-if="tree" :prefix="`/docs`"/>
          </div>
        </div>
        <div class="page-content" v-if="section" ref="page_content">
          <h1>{{section.name}} <span class="section-version">Documentation</span></h1>
          <template v-if="section">
            <p v-if="section.description">{{ section.description }}</p>
            <template v-if="section.versions">
              <h2>Versions</h2>
              <template v-if="section.versions.length > 1">
                <p>Select the version of our {{section.name}} documentation that best matches your setup.</p>
              </template>
              <template v-else>
                <p>Only one version of our {{section.name}} documentation has been released to-date.</p>
              </template>
              <card-layout class="version-list">
                <card-row>
                  <card v-for="version in section.versions"
                    :clickable="true"
                    :href="`${section.slug}/${version.version_slug}`"
                    :wide="true"
                    style="background: white;"
                    >
                    <h3>{{section.name}} <span class="section-version">{{ version.version }} Documentation</span></h3>
                  </card>
                </card-row>
              </card-layout>
              <ul class="version-list">
              </ul>
              <p class="info">
                <icon-inline><info-icon/></icon-inline> New documentation is typically released alongside major/minor product updates.
              </p>
            </template>
            <template v-else>
              <p class="info">
                <icon-inline><info-icon/></icon-inline> There are no versions published under this section, yet!
              </p>
            </template>
          </template>
          <loader v-else/>
        </div>
        <div class="page-outline">
          <div class="page-outline-content">
            <h2>On this page 📃</h2>
            <outline v-if="parts" :parts="parts"/>
            <div v-else>Loading</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReleaseTag from "../components/releases/ReleaseTag"
import Timestamp from "../components/UI/Timestamp"
import MainHeader from "../components/UI/MainHeader"
import Loader from "../components/UI/Loader"
import Card from "../components/UI/Card.vue"
import CardRow from "../components/UI/CardRow.vue"
import CardLayout from "../components/UI/CardLayout.vue"
import Outline from "../components/docs/Outline"
import Tree from "../components/docs/Tree.vue"
import IconInline from '../components/UI/IconInline.vue'
import InfoIcon from "../components/icons/InfoIcon.vue"
import OutlineIcon from '../components/icons/OutlineIcon.vue'
import UnfoldLessIcon from '../components/icons/UnfoldLessIcon.vue'
import UnfoldMoreIcon from '../components/icons/UnfoldMoreIcon.vue'
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon.vue'
import ChevronRightIcon from '../components/icons/ChevronRightIcon.vue'
import * as API from "../utils/js/api"
import * as Entries from "../utils/js/entries"
import * as Docs from "../utils/js/docs"
import slugify from "../utils/js/slugify"
import sleep from "../utils/js/sleep"

export default {
  components: {
    MainHeader,
    Loader,
    ReleaseTag,
    Timestamp,
    Card,
    CardRow,
    CardLayout,
    Outline,
    Tree,
    IconInline,
    InfoIcon,
    OutlineIcon,
    UnfoldLessIcon,
    UnfoldMoreIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      section: null,
      tree: null,
      parts: null,
    };
  },
  mounted() {},
  methods: {
    getSidebarTree() {
      return [
        {
          'name': 'Overview',
          'slug': '',
          'path': `/docs`,
          'type': 'document',
        },
        ...this.tree
      ]
    },
    async getSections() {
      const params = {
        'populate[versions]': '1',
        'populate[product][populate]': '*',
      }

      const response = await API.get(`/docs-sections`, params)
      const sections = Entries.restructureList(response.data.data)

      for (const section of sections) {
        for (const version of section.versions) {
          Docs.restructureRootNodes(version)
        }
      }

      const visibleSections = Docs.getVisibleSections(sections)
      const {sectionsWithNoCategory, categories} = await Docs.byCategory(visibleSections)
      const tree = Docs.flattenCategoryTree(categories, sectionsWithNoCategory)

      this.tree = tree
    },
    async getSection() {
      const params = {
        'filters[slug]': this.$route.params.section,
        'populate[versions]': '1',
        'populate[product][populate]': '*',
      }

      const response = await API.get(`/docs-sections`, params)
      const section = Entries.restructureEntry(response.data.data[0])

      for(const version of section.versions) {
        Docs.restructureRootNodes(version)
      }

      this.section = section

      await sleep(1000)
      await this.getParts()
    },
    async getParts() {
      const content = this.$refs.page_content
      let headings = content.querySelectorAll('h1, h2');
      let parts = []
      for (const heading of headings) {
        heading.id = slugify(heading.textContent)
        parts.push({
          text: heading.textContent,
          level: heading.tagName.substring(1),
          anchor: heading.id,
        });
      }

      parts[0].text = 'About'

      this.parts = parts
    }
  },
  created() {
    this.getSection()
    this.getSections()
  },
  watch: {
    '$route' (to, from) {
      if(to.path !== from.path) {
        this.getSection()
        this.getSections()
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
.page-sidebar-content {
  .section-title {
    font-size 1.15em
    margin-bottom 0.5em
  }

  .version-select {
    font-size 0.9rem
    font-weight 200

    .version-select-dropdown {
      font-size 0.9rem
      font-weight 200
    
      background none
      border none

      option {
        font-size inherit
        font-weight inherit
        font-family "myriad-pro", sans-serif
      }
    }
  }
}

.page-content {
  .section-version {
    font-weight 200
  }

  .card-layout.version-list .card h3:before {
    content "📘 "
  }
}
</style>