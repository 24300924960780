<template>
  <svg
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24">
    <path
      d="m356-160-56-56 180-180 180 180-56 56-124-124-124 124Zm124-404L300-744l56-56 124 124 124-124 56 56-180 180Z"
      fill="#000000"/>
  </svg>
</template>

<script>
export default {
  name: "UnfoldLessIcon",
};
</script>
  