<template>
  <admonition type="warning" class="pre-release-warning">Versions starting with zero should be considered unstable and are subject to breaking changes at any time.</admonition>
</template>

<script>
import Admonition from '../Admonition.vue';

export default {
  name: "PreReleaseWarning",
  components: {
    Admonition,
  },
};
</script>