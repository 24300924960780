import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'
import store from './store'
import hljs from 'highlight.js/lib/core';
import vuePlugin from "@highlightjs/vue-plugin";
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(VueCookies, { expires: '365d'})
Vue.use(vuePlugin);
Vue.use(PerfectScrollbar)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')