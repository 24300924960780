<template>
  <span v-if="record && when == 'createdAtRelative'" class="timestamp"><span class="date">{{record.createdAtRelativeDate}}</span><span class="time"> at {{record.createdAtTime}}</span></span>
  <span v-else class="timestamp"><span class="date">{{date}}</span><span class="time"> at {{time}}</span></span>
</template>

<script>
export default {
name: "Timestamp",
props: ["date", "time", "record", "when"],
};
</script>

<style lang="stylus" scoped>
.timestamp {
  color $secondary

  .date {
    font-weight 400
    color $secondary
  }

  .time {
    font-weight 200
  }
}
</style>