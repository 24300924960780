<template>
  <div class="badge" :class="{'badge-no-img': src === undefined, 'badge-small': small}">
    <div class="badge-content">
      <img class="badge-img" v-if="src" :src="src" :alt="title + ' badge image.'"/>
      <component class="badge-title" :is="`h${depth}`" v-if="title">{{ title }}</component>
      <component class="badge-title" :is="`h${depth}`" v-else-if="release" >{{ release.product.name }} <release-tag :release="release" :clickable="release_is_clickable" /></component>
      <small class="badge-subtitle" v-if="!small && (subtitle || release || is_legacy)">
        <template v-if="subtitle">
          <template v-if="subtitle_href">
            <a :href="subtitle_href" target="_blank" rel="noopener noreferrer">{{ subtitle }}</a>          
          </template>
          <tempalte v-else-if="subtitle_to">
            <router-link :to="subtitle_to">{{ subtitle }}</router-link>          
          </tempalte>
          <template v-else>
            {{ subtitle }}
          </template>
        </template>
        <template v-else-if="release">
          <timestamp :record="release" :when="'createdAtRelative'" />
        </template>
        <template v-if="is_legacy">
          <div class="label" data-label="is_legacy">Legacy</div>
        </template>
      </small>
    </div>
  </div>
</template>

<script>
import ReleaseTag from '../releases/ReleaseTag.vue'
import Timestamp from './Timestamp.vue';

export default {
  name: "Badge",
  props: ["title", "subtitle", "subtitle_href", "subtitle_to", "src", "depth", "release", "release_is_clickable", "is_legacy", "small"],
  components: {
    ReleaseTag,
    Timestamp,
  },
};
</script>

<style lang="stylus">
.badge {
  margin-bottom 10px

  .badge-content {
    position relative

    padding-top 0px
    padding-left 70px
    min-height 60px

    .badge-title {
      margin unset
    }
  
    .badge-subtitle {
      font-weight 200

      .label::before {
        content " "
      }
    }
  
    .badge-img {
      position absolute
      width 60px
      height 60px
      top 0px
      left 0px
      margin unset !important
    }
  }

  &-small {
    .badge-content {
      padding-left 40px
      min-height 30px
      
      .badge-title {
        font-size 1.5rem
      }

      .badge-img {
        width 30px
        height 30px
      }
    }
  }

  &-no-img {
    .badge-content {
      padding-left 0px
    }
  }
}
</style>