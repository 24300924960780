<template>
  <footer class="main-footer">
    <div class="main-footer_logo">
      <router-link to="/"><img src="/assets/images/haply-icon-black.svg" alt /></router-link>
      <h2><router-link class="title-link" to="/"><strong>Haply</strong>&nbsp;Developer&nbsp;Hub</router-link></h2>
    </div>
    <div class="main-footer_columns">
      <ul class="main-footer_column">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/releases">Releases</router-link></li>
        <li><<a href="https://docs.haply.co/" rel="noopener noreferrer"><span>Docs</span></a></li>
      </ul> 
      <ul class="main-footer_column">
        <li><a href="https://haply.co/" target="_blank" rel="noopener noreferrer">Main site <open-in-new class="icon-inline"/></a></li>
      </ul>
      <ul class="main-footer_column">
        <li><a href="https://www.haply.co/inverse3" target="_blank" rel="noopener noreferrer">Inverse3 <open-in-new class="icon-inline"/></a></li>
      </ul>
    </div>
    <span class="main-footer_copyright">Copyright © 2023 Robotiques Haply, Inc. All rights reserved.</span>
  </footer>
</template>

<script>
import OpenInNew from "../icons/OpenInNew";

export default {
  name: "MainFooter",
  components: {
    OpenInNew,
  },
};
</script>

<style lang="stylus" scoped>

img.icon-inline {
  width 0.8em
  height 0.8em
  max-width 0.8em
  max-height 0.8em
}

.main-footer {
  width 100vw
  max-width 100%
  background white
  color #222
  padding-left $horPadding
  padding-right $horPadding
  padding-top 70px
  padding-bottom 100px
  display flex
  justify-content space-between
  flex-wrap wrap
  position relative
  z-index 1
  box-shadow 0px 0px 10px #0001

  & a {
    border-bottom 1px solid #0000
    text-decoration none
  }

  & a:not(.title-link):hover {
    border-bottom 1px solid #0000
    color $active
    filter brightness(80%)

    svg.icon /deep/ path:not([fill="none"]) {
      fill $active 
    }
  }

  // align-items center
  &_logo {
    display flex
    flex-direction column
    align-items center
    justify-content center
    padding 0 40px
    flex-grow 1
  }
  
  &_logo {
    display flex
    flex-direction row
    flex-wrap wrap
    align-items center
    justify-content center
    flex-grow 1
    gap 20px * 1.57894736842
    padding 0 50px

    img {
      width 60px
      height auto
    }

    h2 {
      margin-bottom 35px
    }
    
    a {
      border-bottom none
    }

    a:hover {
      border-bottom none
    }

    * {
      font-weight 200
    }

    strong {
      font-weight 400
    }
  }

  &_columns {
    display flex
    justify-content space-around
    flex-grow 1

    ul {
      padding 0 50px
      list-style none

      & * {
        font-weight 200
        text-transform uppercase
        text-align right
      }

      &:not(:first-child) {
        //border-left solid #ddd 1px
      }

      li {
        margin 20px 0
        cursor pointer
        color #222
      }
    }
  }

  +below(900px) {
    flex-direction column

    &_columns {
      margin-top 20px

      &_mid {
        border-left solid #ddd 1px
        border-right solid #ddd 1px
        padding 0 20px !important
        margin 0 20px !important
      }

      ul {
        padding 0

        li {
          margin 20px 0
          cursor pointer
        }
      }
    }
  }

  +below(600px) {
    flex-direction column
    padding-left 10px
    padding-right 10px

    &_columns {
      display block

      &_mid {
        border none
        margin 0 !important
        padding 0 !important
      }
      ul {
        margin-left unset
        margin-right 50px
        font-size 1.15em
      }
      ul li {
        margin 5px 0
        text-align right
      }
    }
  }

  &_copyright {
    font-size 0.9rem
    color $secondaryAccent
    font-weight 200
    display block
    position absolute
    left 0
    right 0
    bottom 0
    text-align center
    margin-bottom 20px
  }
}
</style>